import React, { useEffect, useState } from 'react';

// icon
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";

// instructions
import {lin} from "../instructions/lin"
import {log} from "../instructions/log"
import {ssb} from "../instructions/ssb"
import {eplusm} from "../instructions/eplusm"
import {facet} from "../instructions/facet"


// Single Step Component
export default function Instructions({nextTrial, trialID, training, visualizationDesign}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    let steps;

    const handleClick = () => {
        window.location.href = "https://app.prolific.com/submissions/complete?cc=C1EMEKOU";
    };


    if(visualizationDesign === "ssb"){
        steps = [...ssb];
    } else if(visualizationDesign === "log"){
        steps = [...log];
    } else if(visualizationDesign === "eplusm"){
        steps = [...eplusm];
    } else if(visualizationDesign === "facet"){
        steps = [...facet];
    } else if(visualizationDesign === "lin"){
        steps = [...lin];
    } else {
        steps = [];
    }

    

    const [incorrectTrialsQ1, setIncorrectTrialsQ1] = useState(0);
    const [incorrectTrialsQ2, setIncorrectTrialsQ2] = useState(0);
    const answerQ1 = 3;
    const answerQ2 = 1;
    const [correctQ1, setCorrectQ1] = useState(false);
    const [correctQ2, setCorrectQ2] = useState(false);
    const [currentResponseQ1, setCurrentResponseQ1] = useState(0);
    const [currentResponseQ2, setCurrentResponseQ2] = useState(0);

    const goToPrevious = () => {
        if(currentIndex > 0) {
            setCurrentIndex((prevIndex) => (prevIndex - 1));
        }
      };
    
      const goToNext = () => {
        if(currentIndex < steps.length) {
            setCurrentIndex((prevIndex) => (prevIndex + 1));
        }
      };

    const handleResponseForQ1 = (e) => {
        const response = parseInt(e.target.value)
        setCurrentResponseQ1(response);
        if(response == answerQ1) {
            setCorrectQ1(true);
        } else {
            setIncorrectTrialsQ1((prevNumber) => (prevNumber + 1));
        }
    }

    const handleResponseForQ2 = (e) => {
        const response = parseInt(e.target.value);
        setCurrentResponseQ2(response);
        if(response == answerQ2) {
            setCorrectQ2(true);
        } else {
            setIncorrectTrialsQ2((prevNumber) => (prevNumber + 1));
        }
        
    }

    return (
        <>
            {incorrectTrialsQ1 > 1 || incorrectTrialsQ2 > 1 
            ?
            <div className='center'>
                <p> Unfortunately, you didn't pass the comprehension test and therefore you cannot participate in the study. Based on Prolific's policy, we will request to return your submission immediately. Thank you for the interest! Have a great day!</p>
                <button 
                    className="large" 
                    onClick={handleClick} 
                    >Go back to Prolif
                </button>
            </div>
            :
            <div className="instructions">
                <h2>Instructions: {currentIndex < steps.length ?  steps[currentIndex].title : " Comprehension Check"}</h2>
                <hr/>
            {currentIndex < steps.length 
            ?
            <div className="carousel">
                <div className="carousel-item">
                    <img src={steps[currentIndex].image} alt={`Screen ${currentIndex+1}`} />
                </div>             
            </div>
            :
            <div>
                <br/>
                <p>To ensure high-quality responses, it is essential that you fully understand the provided instructions. To confirm your comprehension, please answer the following questions. If you are unsure about any answers, click on "Re-read the instructions" to review the material.
                    <b>You have two opportunities to answer each question correctly. If you do not succeed, per Prolific's policy, we will request that you return your submission immediately, and you will not receive payment.</b>
                    </p>
                    <br/>
                <p><b>Based on the instructions you have just read, once you provided the answer what do you have to do?</b></p>
                {correctQ1 ? <p>You provided the correct answer.</p> : 
                    <p>You have <u>{incorrectTrialsQ1 < 1 ? "two opportunities" : "one left opportunity"}</u> to get this question correct. Your answer will be recorded the moment you click on a choice, so please be sure of your selection before clicking.</p>
                }
                <table className='likert-scale'>
                <tbody>
                        <tr>
                        <td><input type="radio" name="q1" onChange={handleResponseForQ1} value="1"/></td>
                        <th>State how difficult you found the task.{currentResponseQ1 == 1 && <span style={{color:"red"}}> Wrong answer. Try again.</span>}</th>
                        </tr>
                        <tr>
                        <td><input type="radio" name="q1" onChange={handleResponseForQ1} value="2"/></td>
                        <th>Wait one minute to be able to proceed to the next trial. {currentResponseQ1 == 2 && <span style={{color:"red"}}>Wrong answer. Try again.</span>}</th>
                        </tr>
                        <tr>
                        <td><input type="radio" name="q1" onChange={handleResponseForQ1} value="3"/></td>
                        <th>State how confident you are for your answer. {currentResponseQ1 == 3 && <span style={{color:"green"}}>Correct!</span>}</th>
                        </tr>
                        <tr>
                        <td><input type="radio" name="q1" onChange={handleResponseForQ1} value="4"/></td>
                        <th>Provide textual feedback explaining the strategy you followed to find the answer. {currentResponseQ1 == 4 && <span style={{color:"red"}}>Wrong answer. Try again.</span>}</th>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <p><b>Based on the instructions you have just read, what will happen if you spent more than one and a half minute for a trial?</b></p>
                {correctQ2 ? <p>You provided the correct answer.</p> : 
                <p>You have <u>{incorrectTrialsQ2 < 1 ? "two opportunities" : "one left opportunity"}</u> to get this question correct. Your answer will be recorded the moment you click on a choice, so please be sure of your selection before clicking.</p>
                }   
                <table className='likert-scale'>
                    <tbody>
                        <tr>
                        <td><input type="radio" name="q2" onChange={handleResponseForQ2} value="1"/></td>
                        <th>You will automatically move to the next trial. {currentResponseQ2 == 1 && <span style={{color:"green"}}>Correct!</span>}</th>
                        </tr>
                        <tr>
                        <td><input type="radio" name="q2" onChange={handleResponseForQ2} value="2"/></td>
                        <th>You will have to provide textual feedback for the reasons of your delay. {currentResponseQ2 == 2 && <span style={{color:"red"}}> Wrong answer. Try again.</span>}</th>
                        </tr>
                        <tr>
                        <td><input type="radio" name="q2" onChange={handleResponseForQ2} value="3"/></td>
                        <th>The experiment will finish and you will be rejected. {currentResponseQ2 == 3 && <span style={{color:"red"}}> Wrong answer. Try again.</span>}</th>
                        </tr>
                        <tr> 
                        <td><input type="radio" name="q2" onChange={handleResponseForQ2} value="4"/></td>
                        <th>The correct answer will be displayed and you will have to complete one extra trial. {currentResponseQ2 == 4 && <span style={{color:"red"}}> Wrong answer. Try again.</span>}</th>
                        </tr>
                    </tbody>
                </table>
                <br/>
            </div>
            }          
            <div className="space-in-between-buttons">
                <button 
                    className={`large ${currentIndex > 0 ? '' : 'hide'}`}
                    id={currentIndex < steps.length ? "prev" : ""}   
                    disabled={false}
                    onClick={goToPrevious} 
                    ><FaArrowLeft/>&nbsp;{currentIndex < steps.length ? "Previous" : "Re-read the instructions"}
                </button>
                <button 
                    className={`large ${!(currentIndex < steps.length) && (!correctQ1 || !correctQ2) ? 'disabled' : 'enabled'}`}  
                    id={currentIndex < steps.length ? "next" : ""}  
                    disabled={!(currentIndex < steps.length) && (!correctQ1 || !correctQ2)}
                    value={parseInt(trialID) + 1} 
                    onClick={currentIndex < steps.length ? goToNext : nextTrial} 
                    > {currentIndex < steps.length ? "Next" : "Ready to start the experiment"}&nbsp;<FaArrowRight/>
                </button>
            </div>   

        </div>
        }
        </>
    );
}











