import React, { useEffect, useState } from 'react';

// icons
import { FaStar, FaRegStar, FaArrowRight } from "react-icons/fa6";
import { FaStarHalfAlt } from "react-icons/fa";

// methods
import {blockPerformance, getStars} from "../utils";

// Single Step Component
export default function FinalPage({totalTrials, feedback, experience, handleFeedback, handleExperience, totalStars, starsBlockRatio, theEnd, handleTheEnd}) {
    const { fullStars, hasHalfStar, emptyStars } = getStars(totalStars, totalTrials)
    const handleClick = () => {
        handleTheEnd();
        window.location.href = "https://app.prolific.com/submissions/complete?cc=C12FD9IM";
    };

    const blockPerformance = () => {
        const stars = starsBlockRatio;
        const performace = 1 - (totalStars - stars)/totalStars;
    
        switch (true) {
            case performace > 0.90:
                return "Excellent! You successfully completed the last block collecting " + stars + " out of the 45 stars.";
            case performace > 0.80:
                return "Good effort! You successfully completed the last block collecting " + stars + " out of the 45 stars.";
            case performace > 0.70:
                return "Not bad! You successfully completed the last block collecting " + stars + " out of the 45 stars.";
            case performace > 0.60:
                return "Thank you for participating! You completed the last block collecting " + stars + " out of the 45 stars.";
            case performace > 0.50:
                return "You can do better! You completed the last block collecting " + stars + " out of the 45 stars.";
            default:
                return "You can do better! You completed the last block collecting " + stars + " out of the 45 stars.";
        }
    
    }

    return (
        <div className="theEnd">
            {!theEnd 
            ?
            <div className="center" style={{textAlign:"left"}}>
                <h3> You have almost reached the end! </h3>
                <p>Thank you for participating! You completed the last block collecting {starsBlockRatio} out of the 45 stars, and you have earned {totalStars} stars in total out of the {totalTrials*5}. </p>
                <div className="centered-container">
                    <span>Your overall score is: {(totalStars/totalTrials).toFixed(1)}/5</span>
                    <div className="stars-container">
                        {[...Array(fullStars)].map((_, index) => (
                            <FaStar key={index} className="star" />
                        ))}
                        {hasHalfStar && <FaStarHalfAlt className="star" />}
                        {[...Array(emptyStars)].map((_, index) => (
                            <FaRegStar key={index} className="star" />
                        ))}
                    </div>
                </div>
                <hr/>
                <p>
                    We would greatly appreciate it if you could provide us with your feedback to help us better <br/>
                    understand the factors that influence the perception of large numbers in the visualizations you saw. <br/>
                </p>
                <p><b>Your insights are invaluable to us and will help improve future studies.</b></p>
                <br/>
                <p>Please consider the following questions:</p>
               
                <p><b>How would you rate your overall experience with the experiment?</b></p>
                <table className='likert-scale horizontal'>
                    <tbody>
                        <tr>
                            <th></th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Not good at all</td>
                            <td><input type="radio" onChange={handleExperience} name="experience" value="1"/></td>
                            <td><input type="radio" onChange={handleExperience} name="experience" value="2"/></td>
                            <td><input type="radio" onChange={handleExperience} name="experience" value="3"/></td>
                            <td><input type="radio" onChange={handleExperience} name="experience" value="4"/></td>
                            <td><input type="radio" onChange={handleExperience} name="experience" value="5"/></td>
                            {/* <td><input type="radio" onChange={handleExperience} name="experience" value="6"/></td> */}
                            <td>Extremely good</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <ul>
                    <li>How effective were the visualizations in helping you performing the tasks? Did you encounter any problem?</li>
                    {/* <li>What strategy did you follow to compare the two values?</li> */}
                    <li>Do you have any suggestions for improving the visualization?</li>
                </ul>
                <textarea 
                    id="exampleTextarea" 
                    onBlur={handleFeedback} 
                    rows="10" 
                    cols="100"
                    placeholder='Provide your feedback here.'
                />
                <br/>
                 {(feedback === "" && experience === 0)  && <small>Provide feedback and rate your experience to proceed.</small>}
                 {!(feedback === "" && experience === 0) && (feedback === "")  && <small>Provide feedback, then click outside the text box.</small>}
                 {!(feedback === "" && experience === 0)  && (experience === 0)  && <small>Rate your experience to proceed.</small>}
                <button 
                    className={`large ${(feedback === "" || experience === 0) ? 'disabled' : 'enabled'}`} 
                    disabled={(feedback === "" || experience === 0)} 
                    onClick={handleClick} 
                    >Towards the end&nbsp;<FaArrowRight/>
                </button>
               
            </div>
            :
            <div className="center">
                <h1>THE END</h1>
                <hr/>
                <h4>Thank you for participating 😊!</h4>
                <h3>You are being redirected back to Prolific...</h3>
            </div>
        }
        </div>

    );
}


          