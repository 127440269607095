/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import * as Plot from '@observablehq/plot';
// Custom hooks
import useWindowDimensions from './useWindowDimensions';


const PlotComponent = ({data}) => {
  const chartContainer = useRef(null);
  // style variables
  const colorFill = '#d6d8e7';
  const colorStroke = '#1c1e53';
  const marginLeft = 100;
  const marginRight = 100;
  const marginTop = 50;
  const marginBottom = 50;
  const { width, height } = useWindowDimensions();

  const maxValueScale = 10**8



  useEffect(() => {
    try {
      // Function to update the chart when data or visual variables or marks change
      if (data === undefined) return;
      const chart = updateChart();
      const plot = Plot.plot(chart);
      plot.setAttribute('role', 'main');
      chartContainer.current.append(plot);
      // eslint-disable-next-line consistent-return
      return () => plot.remove(); // remove the previous chart and redraw
    } catch (e) {
      console.error(e);
    }
  }, [data, width, height]);

  // Function to update the Plot chart
  const updateChart = () => {
    // Initialize chart with base configuration and directly include conditional properties
    const chart = {
      width,
      height,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      grid: true,
      x: {label:null},
      y:{type:"log", domain:[1, maxValueScale], tickFormat:d=> tickValues(maxValueScale).includes(d) ? d : null},
      marks: [
        Plot.barY(data, {x: "Category", y1:1, y2:d=> parseInt(d["Value"]),  fill:colorFill, opacity:0.9, stroke:colorStroke, insetLeft:5, insetRight:5,}),
        Plot.ruleY([0]),
        Plot.axisX({lineWidth: 4, marginBottom: 40}),
      ]
    };
  
    return chart;
  };

  function tickValues(maxValue) {
    //display only the 10**exponent and 5*10**exponent to give a sence of the middle  
    let result = [];
      let current = 1;
      let multiplyByFive = true; // Start by multiplying by 5, then alternate
  
      while (current <= maxValue) {
          result.push(current);
          current = multiplyByFive ? current * 5 : current * 2;
          multiplyByFive = !multiplyByFive; // Toggle the multiplier for the next iteration
      }
  
      return result;
  }


  // Render the SVG container
  return (
    <div ref={chartContainer}></div>   
  );
};

export default PlotComponent;
