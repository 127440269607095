import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
// Custom hooks
import useWindowDimensions from '../useWindowDimensions';

const getMantissa = (value) => {
  // To calculate the base-1000 logarithm using Math.log(value), 
  // you would need to adjust the formula to convert from the natural logarithm to the base-1000 logarithm.
  const baseLog = Math.log(value) / Math.log(10);
  return value / (10 ** Math.floor(baseLog));
}


const LinChart = ({data, selectedCategory1, selectedCategory2, width, height}) => {
  // Ref to hold the SVG container
  const chartContainer = useRef(null);

  // constants
  const marginLeft = 60;
  const marginRight = 60;
  const marginTop = 40;
  const marginBottom = 60;
  //const { width, height } = useWindowDimensions();
  // const width = 768;
  // const height = 531;
  const selectedColor = "#F85741";

  // Specify categories to change color
  const category1 = data.find(category => category.Category === selectedCategory1);
  const category2 = data.find(category => category.Category === selectedCategory2);
  const categoriesToHighlight = [];
  const maxValue = d3.max(data, d => parseFloat(d.Value));
  const maxExponent = Math.floor(Math.log10(d3.max(data, d => parseFloat(d.Value))));
  const mantissaOfMaxValue = Math.trunc(getMantissa(maxValue));
  if(category1 != undefined) categoriesToHighlight.push(category1);
  if(category2 != undefined) categoriesToHighlight.push(category2);



  useEffect(() => {
    // Function to update the chart when data or visual variables or digits change
    if (data === undefined) return;
    updateChart();
  }, [data]);

  const scaleY = (value) => {
    console.log(value)
    let num, unit;
    if(value >= 1000 && value <1000000){
      num = parseInt(value/1000)
      unit = "k"
    } else if (value >= 1000000 && value <1000000000){
      num = parseInt(value/1000000)
      unit = "M"
    } else if (value >= 1000000000 && value <1000000000000){
      num = parseInt(value/1000000000)
      unit = "B"
    } else {
      num = value;
      unit = "";
    }
    return num + unit;
}

  const strongTicks=[]
  for(let i=0; i<=mantissaOfMaxValue; i++){
    strongTicks.push(i*(10**maxExponent))
  }

  const horizontalLines=[]
  for(let i=0; i<=mantissaOfMaxValue; i++){
    horizontalLines.push((i+0.25)*(10**maxExponent))
    horizontalLines.push((i+0.5)*(10**maxExponent))
    horizontalLines.push((i+0.75)*(10**maxExponent))
  }

  const midTicks=[]
  for(let i=0; i<=mantissaOfMaxValue; i++){
    midTicks.push((i+0.5)*(10**maxExponent))
  }




  // Function to update the D3 chart
  const updateChart = () => {
     // Declare the x (horizontal position) scale.
    const x = d3.scaleBand()
    .domain(data.map(d => d.Category).sort())
    // .domain(d3.shuffle(data.map(d => d.Category))) // Shuffle the order randomly
    .range([marginLeft, width - marginRight])
    .padding(0.4);

    // Declare the y (vertical position) scale.
    const y = d3.scaleLinear()
    .domain([0, d3.max(data, d => parseFloat(d.Value))])
    .nice()
    .range([height - marginBottom, marginTop]);


    // Select the SVG container
    const svg = d3.select(chartContainer.current);

    svg.attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; max-height:100%; min-width: 100%; min-height:100%");

    // Remove existing elements to avoid duplication
    svg.selectAll('*').remove();

    // Define the arrow marker
    svg.append("defs").append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 5)
      .attr("refY", 5)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 z")
      .attr("fill", selectedColor);

    // Add the x-axis and label.
    const xAxis = svg.append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(0));

    // Add grey grid lines
    const yAxis = d3.axisLeft(y)
      .tickValues(midTicks)
      .tickSize(-width + marginLeft + marginRight)
      .tickFormat(d=> scaleY(d));

    svg.append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis)
      .call(g => g.select(".domain").remove()) // Remove the domain line
      .call(g => g.selectAll(".tick line").attr("stroke", "#ccc"));

    // Add horizontal grid lines - thick
    svg.append("g")
    .attr("class", "grid-strong")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(strongTicks)
        .tickSize(-width + marginLeft + marginRight)
        .tickFormat(d=> scaleY(d))
    ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

    // Add horizontal grid lines - thin
    svg.append("g")
    .attr("class", "grid")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(horizontalLines)
        .tickSize(-width + marginLeft + marginRight)
        .tickFormat('')
    ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

    // Add a rect for each bar.
    svg.append("g")
    .attr("fill", "#14164C")
    .selectAll()
    .data(data)
    .join("rect")
    .attr("x", (d) => x(d.Category))
    .attr("y", (d) => y(d.Value))
    .attr("height", (d) => y(0) - y(d.Value))
    .attr("width", x.bandwidth());


    // Change color of specific categories on x-axis
    xAxis.selectAll(".tick text")
        .style("fill", function(d) {
            return categoriesToHighlight.some(c => c.Category === d) ? selectedColor : "black";
        })
        .style("font-weight", function(d) {
            return categoriesToHighlight.some(c => c.Category === d) ? "bold" : "normal";
        });

      // Add y-axis label
      svg.append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop - 10)
        .attr("text-anchor", "end")
        .text("Value")
        .attr("font-size", "14px");

      // Add legend for units explanation
      svg.append("text")
      .attr("x", marginLeft)
      .attr("y", height - 5)  // Position it above the bottom margin
      .attr("text-anchor", "start")
      .text("B = Billion (1,000,000,000)")
      .attr("alignment-baseline", "start")
      .attr("font-size", "14px");


        // Draw arrows pointing to the highlighted ticks
        categoriesToHighlight.forEach(c => {
          if(c.Category == "") return;
          const xPosition = x(c.Category) + x.bandwidth() / 2;
          const yPositionBottom = height-marginBottom/2; // Position below the x-axis
          const yPositionTop = y(c.Value); // Position on top of bar
  
          svg.append("line")
            .attr("x1", xPosition)
            .attr("y1", yPositionBottom ) 
            .attr("x2", xPosition)
            .attr("y2", yPositionBottom  - 5) 
            .attr("stroke", selectedColor)
            .attr("stroke-width", 2)
            .attr("marker-end", "url(#arrow)");
  
            // Calculate the midpoint for rotation
            const xMidpoint = xPosition;
            const yMidpoint = yPositionTop - 20; // Midpoint of the line
    
            svg.append("line")
              .attr("x1", xPosition)
              .attr("y1", yPositionTop) 
              .attr("x2", xPosition)
              .attr("y2", yPositionTop - 30) 
              .attr("stroke", selectedColor)
              .attr("stroke-width", 2)
              .attr("marker-end", "url(#arrow)")
              .attr("transform", `rotate(180, ${xMidpoint}, ${yMidpoint})`);
  
      });
    
  };

  // Render the SVG container
  return <svg ref={chartContainer}></svg>;
};

export default LinChart;