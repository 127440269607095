import React, { useEffect, useState, useMemo } from 'react';

// visualizations
import PlotComponent from '../PlotComponent.js';
import LogChart from '../visualizations/LogChart.js';
import SsbChart from '../visualizations/SsbChart.js';
import LinChart from '../visualizations/LinChart.js';
import DesignOne from '../visualizations/DesignOne.js';
import DesignTwo from '../visualizations/DesignTwo.js';
import EplusM from '../visualizations/EplusM.js';
import Faceting from '../visualizations/Faceting.js';
import Br from './Br.js';

// Methods
import {numberToWords, findStars} from '../utils'

import { FaStar } from "react-icons/fa6";

function getPPI() {
  const screenWidth = window.screen.width; // Screen width in pixels
  const screenHeight = window.screen.height; // Screen height in pixels
  const screenDiagonal = window.screen.diagonal || 15.6; // Screen diagonal size in inches (you might need to hardcode this or obtain it from a reliable source)
  
  // Calculate PPI (pixels per inch)
  return Math.sqrt(screenWidth ** 2 + screenHeight ** 2) / screenDiagonal;
}

function cmToPx(cm) {
  const ppi = getPPI();
  const inch = cm / 2.54;
  return inch * ppi;
}


// Single Step Component
export default function Step({trialID, timeLeft, visualizationDesign, currentTrialSetup, response, correctAnswer, submitResponse, responseText, confidence, selectedUnit, numericalResponse, handleConfidence, handleChangeNumber, handleChangeUnit, firstClick}) {
    const { selectedCategory1, selectedCategory2, data } = currentTrialSetup;
    let task;

    // const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const widthCm = 22;
    const heightCm = 17;
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);
    const [showConfidence, setShowConfidence] = useState(false)


    const units = {"1 - Units":1, "k - Thousand":1000, "M - Million":1000000, "B - Billion":1000000000}
    const trialsPerBlock = {1:4, 2:12, 3:12}
    const totalStars = 5;

    const category1 = data.find(category => category.Category === selectedCategory1);
    const category2 = data.find(category => category.Category === selectedCategory2);

    let maxCategory = null;
    let maxCategoryValue = null;
    let minCategory = null;

    if (category1 && category2) {
      if (category1.Value > category2.Value) {
        maxCategory = category1;
        maxCategoryValue = category1.Value;
        minCategory = category2;
      } else {
        maxCategory = category2;
        maxCategoryValue = category2.Value;
        minCategory = category1;
      }
    } else {
        maxCategory = category1;
        maxCategoryValue = category1.Value;
        minCategory = category2;
    }

    if(currentTrialSetup.task == "Value"){
      task="value"
    } else if(currentTrialSetup.task == "Difference_Same" ||  currentTrialSetup.task == "Difference_Neighbour" || currentTrialSetup.task == "Difference_Distant"){
      task="difference"
    } else {
      task="ratio"
    }

    const updateDimensions = () => {
        setZoomLevel(window.devicePixelRatio);
        const widthPx = cmToPx(widthCm) / window.devicePixelRatio;
        const heightPx = cmToPx(heightCm) / window.devicePixelRatio;
        setDimensions({ width: widthPx, height: heightPx});
    };



    useEffect(() => {
      if(submitResponse) {
        return;
      } else {
        setShowConfidence(false)
      }
    }, [submitResponse]); 




    // const detectZoomLevel = () => {
    //     setZoomLevel(window.devicePixelRatio);
    // };

    useEffect(() => {
        // Initial dimension calculation
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => {
        window.removeEventListener('resize', updateDimensions);
        };
    }, []);



    const getMessage = (stars) => {
      switch(stars) {
          case 5:
              return "Excellent! Your answer is extremely close to the actual value.";
          case 4:
              return "Good effort! Your answer is close to the actual value.";
          case 3:
              return "Not bad! Your answer is not very far from the actual value.";
          case 2:
              return "You can do better! Your answer is not close to the actual value.";
          case 1:
              return "You can do better! Your answer is far from the actual value.";
          default:
              return "No stars yet! Give it another shot.";
      }
  };

    const trialOfBlock = useMemo(() => {
      let trial;
      if (currentTrialSetup.blockID === 1) {
        trial = trialID;
      } else if (currentTrialSetup.blockID === 2) {
        trial = trialID - trialsPerBlock[1];
      } else {
        trial = trialID - trialsPerBlock[1] - trialsPerBlock[2];
      }
      return trial;
    }, [trialID, currentTrialSetup.blockID, trialsPerBlock]);


      // Format the time to display minutes and seconds
      const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
      };

      const handleChangeResponse = (e) => {
        handleChangeNumber(e)
        setShowConfidence(true)
      }

      // Function to round to two mantissa digits
      function roundToTwoMantissaDigits(num) {
        const exponent = Math.floor(Math.log10(num));
        const factor = Math.pow(10, exponent - 1);
        const value =  Math.round(num / factor) * factor;
        return Math.round(value * 10) / 10; //for rounded errors due to IEEE 754 double-precision floating-point numbers
      }

    return (
      <>
      {(dimensions.width > 0 && dimensions.height > 0) &&
    <div className="grid-half">
        <div className="background"></div>
        <div className="plot" style={{ width: `${widthCm}cm`, height: `${heightCm}cm` }}>
        {visualizationDesign == "log" &&
          <LogChart data={currentTrialSetup.data} selectedCategory1={currentTrialSetup.selectedCategory1} selectedCategory2={currentTrialSetup.selectedCategory2} width={dimensions.width} height={dimensions.height}/>
        }
        {visualizationDesign == "ssb" &&
          <SsbChart data={currentTrialSetup.data} selectedCategory1={currentTrialSetup.selectedCategory1} selectedCategory2={currentTrialSetup.selectedCategory2} width={dimensions.width} height={dimensions.height}/>
        }
        {visualizationDesign == "lin" &&
          <LinChart data={currentTrialSetup.data} selectedCategory1={currentTrialSetup.selectedCategory1} selectedCategory2={currentTrialSetup.selectedCategory2} width={dimensions.width} height={dimensions.height}/>
        }
        {visualizationDesign == "d1" &&
          <DesignOne data={currentTrialSetup.data} selectedCategory1={currentTrialSetup.selectedCategory1} selectedCategory2={currentTrialSetup.selectedCategory2} width={dimensions.width} height={dimensions.height}/>
        }
        {visualizationDesign == "d2" &&
          <DesignTwo data={currentTrialSetup.data} selectedCategory1={currentTrialSetup.selectedCategory1} selectedCategory2={currentTrialSetup.selectedCategory2} width={dimensions.width} height={dimensions.height}/>
        }
        {visualizationDesign == "eplusm" &&
          <EplusM data={currentTrialSetup.data} selectedCategory1={currentTrialSetup.selectedCategory1} selectedCategory2={currentTrialSetup.selectedCategory2} width={dimensions.width} height={dimensions.height}/>
        }
        {visualizationDesign == "facet" &&
          <Faceting data={currentTrialSetup.data} selectedCategory1={currentTrialSetup.selectedCategory1} selectedCategory2={currentTrialSetup.selectedCategory2} width={dimensions.width} height={dimensions.height}/>
        }
        </div>
       
        <div className="questions">
          <div className="row">
            <h5>Block: <span className="timer">{currentTrialSetup.blockID}/3</span> </h5>
            <h5>Trial: {trialOfBlock}/{(trialsPerBlock[currentTrialSetup.blockID])} </h5>
            <h5>Time left: <span className={`timer ${timeLeft < 10 ? 'red' : ''}`}>{formatTime(timeLeft)}</span></h5>
          </div>
           <Br/>
           <Br/>
           {/* <p>Task: {currentTrialSetup.task}</p> */}
            <div className='center-questions'>
              {task == "value" &&
              <>
                <h3>What is the value of <span className="selected-category">{currentTrialSetup.selectedCategory1}</span>?</h3>

              </>
            }
            {(task=="ratio") &&
               <>
              <h3>How many times bigger is <Br/><span className="selected-category">{maxCategory.Category}</span> in comparison to <span className="selected-category">{minCategory.Category}</span> ?</h3>
              </>
            }
          {(task=="difference") &&
               <>
              <h3>What is approximately the absolute difference between <span className="selected-category">{maxCategory.Category}</span> and <span className="selected-category">{minCategory.Category}</span> ?</h3>
              </>
            }
              <Br/>
              <label>ANSWER:</label> {(response != null && !isNaN(response)) && <span style={{fontSize:14}}>{responseText}</span>}<Br/>
              <div className="input-container" onClick={firstClick}>
                  <input
                    type="number"
                    step="any"
                    placeholder='0'
                    value={numericalResponse}
                    disabled={submitResponse}
                    onChange={handleChangeResponse}
                    className="input-field">
                  </input>
                  <select value={selectedUnit} onChange={handleChangeUnit} className="select-field" disabled={submitResponse}>
                    {Object.keys(units).map((k, index) => (
                      <option key={index} value={units[k]}>
                        {k}
                      </option>
                    ))}
                  </select>
              </div>
                
               
                <Br/>
                {
                  showConfidence &&
                  <>
                  <p><b>How confident are you for your answer?</b></p>
                  <table className='likert-scale'>
                      <tbody>
                          <tr>
                            <td><input type="radio" checked={confidence == 1} onChange={handleConfidence} value="1" disabled={submitResponse || response===""}/></td>
                            <th>1 - Not Confident at all</th>
                          </tr>
                          <tr>
                            <td><input type="radio" checked={confidence == 2} onChange={handleConfidence} value="2" disabled={submitResponse || response===""}/></td>
                            <th>2 - Slightly Confident</th>
                          </tr>
                          <tr>
                            <td><input type="radio" checked={confidence == 3} onChange={handleConfidence} value="3" disabled={submitResponse || response===""}/></td>
                            <th>3 - Moderately Confident</th>
                          </tr>
                          <tr>
                            <td><input type="radio" checked={confidence == 4} onChange={handleConfidence} value="4" disabled={submitResponse || response===""}/></td>
                            <th>4 - Very Confident</th>
                          </tr>
                          <tr>
                            <td><input type="radio" checked={confidence == 5} onChange={handleConfidence} value="5" disabled={submitResponse || response===""}/></td>
                            <th>5 - Extremely Confident</th>
                          </tr>
                      </tbody>
                  </table>
                  <Br/>
                  </>
                  
                }

                {submitResponse &&
                <div className="correctValue">
                   {/* {stars(correctAnswer, response)} stars <Br/> */}
                   <p>{getMessage(findStars(correctAnswer, response, maxCategoryValue, task))}</p>
                   {/* The correct answer is approximately: <Br/><b>{roundToTwoMantissaDigits(correctAnswer.toFixed(1))} ({numberToWords(roundToTwoMantissaDigits(correctAnswer.toFixed(1)))})</b><Br/> */}
                   <hr className='hr-white'/>
                   <div>
                      {[...Array(totalStars)].map((_, index) => (
                        <FaStar key={index} style={{ color: (index < findStars(correctAnswer, response, maxCategoryValue, task)) ? '#7275c6' : '#bdbdc2', fontSize: '16px' }}/>
                      ))}
                  </div>
                </div>
                }
               
            </div>
        </div>
      </div>
      }
      </>
    );
}