import React from 'react';
// icons
import { FaStar, FaRegStar } from "react-icons/fa6";
import { FaStarHalfAlt } from "react-icons/fa";

// Methods
import {getStars} from '../utils'

// Single Step Component
export default function BlockScreen({ totalTrials, blockID, nextBlock, starsBlockValue, starsBlockDifference, starsBlockRatio, handleAttentionCheck}) {
    let stars, starsMax, attentionAnswer, blockAttentionName, trials;
    const correctAttentionBlock1 = 1;
    const correctAttentionBlock2 = 3;

    if (blockID == 1) {
        trials = 4;
        stars = starsBlockValue;
        starsMax = 20;
        attentionAnswer = "Strongly Disagree"
        blockAttentionName = "attentionBlock1";
    } else if (blockID == 2) {
        trials = 12;
        stars = starsBlockDifference;
        starsMax = 60;
        attentionAnswer = "Neither Agree nor Disagree"
        blockAttentionName = "attentionBlock2";
    } else if (blockID == 3) {
        trials = 12;
        stars = starsBlockRatio;
        starsMax = 60;
    } else {
        console.log("ERROR: Unknown block id.");
    }

    const { fullStars, hasHalfStar, emptyStars } = getStars(stars, trials);

    const orderOfBlock = () => {
        if (blockID === 1) {
            return "first";
        } else {
            return "second";
        }
    }

    const titleOfBlock = () => {
        if (blockID === 1) {
            return "Value Estimation";
        } else {
            return "Difference Estimation";
        }
    }

    const nextBlockDescription = () => {
        if (blockID === 1) {
            return "In the next block you will have to Estimate the Difference between two categories.";
        } else {
            return "In the next block you will have to Estimate the Ratio between two categories.";
        }
    }

    const handleResponse = (e) => {
        const value = parseInt(e.target.value, 10);
        const attention = (blockID == 1 && value == correctAttentionBlock1) ||  (blockID == 2 && value == correctAttentionBlock2);
        handleAttentionCheck(blockAttentionName, attention);
    }

    const blockPerformance = () => {
        const performance = 1 - (starsMax - stars) / starsMax;

        switch (true) {
            case performance > 0.90:
                return "Excellent! You successfully completed the " + orderOfBlock() + " block collecting " + stars + " out of the " + starsMax + " stars.";
            case performance > 0.80:
                return "Good effort! You successfully completed the " + orderOfBlock() + " block collecting " + stars + " out of the " + starsMax + " stars.";
            case performance > 0.70:
                return "Not bad! You successfully completed the " + orderOfBlock() + " block collecting " + stars + " out of the " + starsMax + " stars.";
            case performance > 0.60:
                return "You can do better! You completed the " + orderOfBlock() + " block collecting " + stars + " out of the " + starsMax + " stars.";
            case performance > 0.50:
                return "You can do better! You completed the " + orderOfBlock() + " block collecting " + stars + " out of the " + starsMax + " stars.";
            default:
                return "You can do better! You completed the " + orderOfBlock() + " block collecting " + stars + " out of the " + starsMax + " stars.";
        }
    }

    return (
        <div className="center">
            <p>{blockPerformance()}</p>
            <div className="centered-container">
                <span>Your overall score is: {(stars / trials).toFixed(1)}/5</span>
                <div className="stars-container">
                    {[...Array(fullStars)].map((_, index) => (
                        <FaStar key={index} className="star" />
                    ))}
                    {hasHalfStar && <FaStarHalfAlt className="star" />}
                    {[...Array(emptyStars)].map((_, index) => (
                        <FaRegStar key={index} className="star" />
                    ))}
                </div>
            </div>

            <h4>{nextBlockDescription()}</h4>
            <p><b>It's important that you pay attention to this study. Please tick "{attentionAnswer}". This is an attention check.</b></p>
            <table className='likert-scale horizontal'>
                <tbody>
                    <tr>
                        <th>Strongly Disagree</th>
                        <th>Disagree</th>
                        <th>Neither Agree nor Disagree</th>
                        <th>Aggree</th>
                        <th>Strongly Aggree</th>
                    </tr>
                    <tr>
                        <td><input type="radio" name="attentionBlocks" onChange={handleResponse} value="1"/></td>
                        <td><input type="radio" name="attentionBlocks" onChange={handleResponse} value="2"/></td>
                        <td><input type="radio" name="attentionBlocks" onChange={handleResponse} value="3"/></td>
                        <td><input type="radio" name="attentionBlocks" onChange={handleResponse} value="4"/></td>
                        <td><input type="radio" name="attentionBlocks" onChange={handleResponse} value="5"/></td>
                    </tr>
                </tbody>
            </table>
            <br/>
                <br/>
            <hr />
            <p>Click on "Proceed to the next block" to continue.</p>
            <button
                className="large"
                onClick={nextBlock}
            >
                Proceed to the next block
            </button>
        </div>
    );
}
