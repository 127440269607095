export const ssb = [
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-start.svg', 
        title:"The Visualization",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-axis.svg', 
        title:"The Visualization", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-scales.gif', 
        title:"The Visualization", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-inside-1.svg', 
        title:"The Visualization",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-inside-2.svg', 
        title:"The Visualization", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-inside-3.svg', 
        title:"The Visualization",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-inside-4.svg', 
        title:"The Visualization", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-similar.gif', 
        title:"The Visualization", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-example-1.svg', 
        title:"The Visualization",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/ssb-example-2.svg', 
        title:"The Visualization", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen1.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen2.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen3.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen4.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen5.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen6.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen7.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen8.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen9.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen10.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen11.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/ssb/screen12.svg', 
        title:"The Experiment", 
        
    },
]