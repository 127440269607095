import React, { useEffect, useState } from 'react';


// Single Step Component
export default function WelcomeScreen() {


    return (
        <div>
            <h1>Welcome to our study!</h1>
            <hr/>
            {/* <p><b>Study name:</b> Visualization Design for Large Numbers</p>
            <p><b>Study duration:</b> Approximately 25 minutes</p>
            <p><b>Study description: </b> 
            This study evaluates the effectiveness of several (one per participant) data visualization designs in representing values that span over multiple orders of magnitude (e.g., from hundreds to billions).
            These values are integral to various domains of daily life, including but not limited to financial analysis, pandemic tracking, demographic studies, environmental monitoring, and social media metrics. 
            The outcomes of this study are expected to enhance our understanding of users' mental models when they engage in quantitative tasks involving large numbers, such as estimating differences and ratios. 
            </p>
            <br/>
            <p>Please respond to this survey only if you:</p>
            <ul>
                <li>are fluent in English.</li>
                <li>are of legal age (18 years in most countries).</li>
                <li>do not have low vision or impaired vision, including any form of color vision deficiency.</li>
                <li>currently viewing this survey from a <b>computer screen</b> that is 13 inches or larger. Tablet or mobile phone's screens are too small to display the visualizations correctly.</li>
            </ul>
            <p><b>Important to remember:</b></p>
            <ul>
                <li>You cannot navigate back to previous pages, with the only exception being the instructions.</li>
                <li>You cannot zoom in or out on the page, and you cannot minimize your browser window.</li>
                <li>Try to stay focused during the experiment and answer as quickly and accurately as possible. <b>Best performers will receive a bonus payment.</b> </li>
                <li>During the experiment, you will need to perform some calculations. We are interested in approximations, not precise values. For example, if you have to calculate 620/30, it is acceptable to give 20 as an answer. Please do not use calculators.</li>
            </ul>
            <br/>
            <p>Click "Next" to proceed to informed consent.</p> */}
              <p>Text removed for anonymization purposes.</p>
        </div>
    );
}











