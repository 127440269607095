import React, { useEffect, useState } from 'react';



// Single Step Component
export default function ConsentForm({}) {
    return (
            <div>
                <h1>Information Notice and Informed Consent</h1>
                <h2>Consent form</h2>
                <hr/>
                <p>Text removed for anonymization purposes.</p>
                {/* <h3><u>PART I/ INFORMATION</u></h3>
                <p>Acknowledgements: <br/>
                Your input is essential to our project, and the researchers involved in this study would like to thank you for it. 
                Before giving your informed consent, it is important that you understand why the research is being done, what it involves, and what your rights and obligations are.
                </p>
                <h4><u>IDENTIFICATION</u></h4>
                <p><b>Project manager(s):</b> Dr. Jean-Daniel Fekete (Senior Research Scientist, Aviz Team, Inria Saclay)</p>
                <p><b>Other scientists involved:</b> Katerina Batziakoudi (Doctoral student, Aviz Team, Inria Saclay, Université Paris-Saclay), Dr. Florent Cabric (Postdoctoral researcher, Aviz Team, Inria Saclay, Université Paris-Saclay)</p>
                <p><b>Project team and Inria Research Centre:</b> Aviz Team - Inria Saclay</p>
                <p><b>Project name:</b> Comparison of visualizations for large-value ranges</p>
                <br/>
                <p>You are invited to take part in this project, whose purpose is to evaluate which visualization design is more suitable for values that span multiple orders of magnitude. The Aviz team conducts research in the field of visualization and the results of these experiments will help us to improve visualization design and research in this field more generally. This study will take place online.</p>
                <br/>
                <p>This project has received a favorable opinion from Inria's Operational Committee for the Assessment of legal and ethical risks. <br/>
                    For more information:
                    <a href="https://www.inria.fr/en/operational-committee-assesment-legal-and-ethical-risks">https://www.inria.fr/en/operational-committee-assesment-legal-and-ethical-risks</a>
                </p>
                <h4><u>YOUR PARTICIPATION IN THE PROJECT</u></h4>
                <h5>Conditions of your participation</h5>
                <p><b>Requirements: </b>Anyone who is a fluent speaker of English, is of legal age (18 years in most countries), have normal or corrected-to-normal vision, and do not suffer from color blindness can participate in this study.</p>
    
                <p><b>Risks and drawbacks: </b>There are no known risks or drawbacks associated with this research study.</p>

                <h5>Voluntary participation in the project</h5>
                <p>Once you have read and understood the information contained in this page, your signature on this form indicates that you agree to participate in the project, and this without any restrictions or external pressure.
                    If you don't have the information that you need to make a decision, you should not hesitate to ask for more information from the contact persons (see below).</p>

                <h5>Withdrawal from the project at any time</h5>
                <p>You are completely free to end your participation in the project at any time before the completion of the study. You don’t have to provide any reasons for your withdrawal.
                Regarding the data concerning you which may have been collected before you withdraw from the project, the research team may continue to use such data for the time during which the data mentioned below is kept, 
                unless you have objections which you should make known to the contact persons. In this case, the data concerning you will be destroyed.
                </p>

                <h5>Request for information during the project</h5>
                <p>If you have any additional questions about the project or about your rights as a participant in this research, please do not hesitate to get in touch with the contact persons mentioned below.
                </p>

                
                <h5>Publication on a public platform for Open Science of the principal results of the research</h5>
                <p>By participating in this experiment, you are informed that the principal results of the research will be published on a public platform for Open Science.
                </p>
                <br/>
                <p>***<br/>
                    This form for requesting information and for collecting informed consent is a confidential and sensitive document, 
                    and as such it must be given special treatment both in the way that it is kept and archived.
                    <br/>
                    A copy of the duly signed form intended for the project manager will be kept within the administrative and financial department of the Inria Research Centre 
                    concerned for fifteen (15) years before being transferred to the archives department of the Inria Research Centre concerned.</p>

                <h4><u>PRIVACY AND DATA PROTECTION ACT</u></h4>
                <p>Inria is very attentive and vigilant regarding personal data protection (DCP) issues. 
                    It has also appointed a Data Protection Officer (DPO) for ensuring that Inria complies with the European General Data Protection Regulation (GDPR).
                </p>
                <br/>
                <p><b>Personal data is collected and processed for the following purposes:</b> <br/>
                We will collect three types of data:</p>
                <ol>
                    <li>Experiment data such as completion time, accuracy, subjective confidence, qualitative feedback for each trial.</li>
                    <li>Identification data through prolific id and background and demographic data such as gender identification, age, familiarity with logarithmic scales.</li>
                </ol>
                <p>The study data will be logged in real time and will be stored on an encrypted server. Every day, the log files will be downloaded onto our Inria — thus, encrypted — computers. The dataset will automatically include Prolific identifiers for all participants. 
                This identifier being the only feature making identification of persons possible, we will remove it from the dataset before analysis, effectively anonymizing the data.
                </p>
                
                <br/>
                <p>In accordance with Article 13 of the Regulation (EU) 2016/679 of the European Parliament and of the council of 27 April 2016 on the protection 
                    of natural persons with regard to the processing of personal data and the free movement of such data, please find below some information about this data collection:
                </p>
                <ol>
                    <li>The controller's representative is the CEO of Inria.</li>
                    <li>The Data Protection Officer (DPO) appointed by Inria can be contacted via <a href="mailto:dpo@inria.fr">dpo@inria.fr</a> or via her postal address: The Data Protection Officer, Inria, 2004 route des Lucioles, BP 93, 06902 Sophia-Antipolis Cedex, France.</li>
                    <li>The purpose of this study is to compare different visualization designs, aiming to derive guidelines for the effective visualization of values that span several orders of magnitude.</li>
                    <li>The legal basis of the processing is the execution of a public interest mission.</li>
                    <li>The recipients of your data are the researchers listed in the consent form.</li>
                    <li>Your data will be stored for a maximum period of 22 months.</li>
                    <li>You may access your personal data or request that it be deleted. You also have a right to object, a right to rectification, and a right to limit the processing of your data (see <a href="https://www.cnil.fr">https://www.cnil.fr</a> for more information on your rights). For any questions about the processing of your data in this system, you can contact the DPO:
                        <ul>
                            <li style={{fontSize:"1rem"}}>Contact our DPO electronically: <a href="mailto:dpo@inria.fr">dpo@inria.fr</a></li>
                            <li style={{fontSize:"1rem"}}>Contact our DPO by post: The Data Protection Officer, Inria, 2004 route des Lucioles, BP 93, 06902 Sophia-Antipolis Cedex, France</li>
                        </ul>
                    </li>
                    <li>If you consider that the processing does not comply with data protection rules, you can file a complaint with the CNIL.</li>
                    <li>This data collection is done on a voluntary basis. Under no circumstances are you required by regulation, contract, or other means to provide your data.</li>
                </ol>

                <p>This form for requesting information and for collecting informed consent is a confidential and sensitive document, 
                    and as such it must be given special treatment both in the way that it is kept and archived.</p>
                <p>A copy of the duly signed form intended for the project manager will be kept within the administrative and financial 
                    department of the Inria Research Centre concerned for fifteen (15) years before being transferred to the archives 
                    department of the Inria Research Centre concerned.</p>

                <br/>
                <h3><u>PART II/ COLLECTION OF INFORMED CONSENT</u></h3>
                <p>I acknowledge that I have read and understood this form for requesting information concerning the project "Comparison of visualizations for large-value ranges" 
                    of which the purpose is to derive design guidelines for the visualization of large value ranges and that I voluntarily agree to participate in this research 
                    project in this context.
                    <br/>
                    I acknowledge that I have been informed that I can terminate my participation in the project at any time without having to give any reason, 
                    by simply contacting the contacts mentioned above.
                    <br/>
                    I acknowledge having been informed about the purpose and conditions of the project, as well as the pros, risks and cons of the project.
                    <br/>
                    I acknowledge having received clear and precise answers to my questions.
                    <br/>
                    Finally, I recognize that I have the option of asking additional questions about the project or about my rights, at any time during the project, 
                    via the contact persons.
                </p>
                <br/>
                <p><b>Contact details:</b><br/>
                Please feel free to contact one of the two researchers below if you wish to know more about the study, or if you have any questions:<br/>
                <b>Katerina Batziakoudi</b>, Doctoral Student, AVIZ Research Team, Inria Saclay Île-de-France, Université Paris-Saclay, <a href="aikaterini.batziakoudi@inria.fr">aikaterini.batziakoudi@inria.fr</a><br/>
                <b>Dr. Jean-Daniel Fekete</b>, Senior Research Scientist, AVIZ Research Team, Inria Saclay Île-de-France, Université Paris-Saclay, <a href="jean-daniel.fekete@inria.fr">jean-daniel.fekete@inria.fr</a>
                </p>
                <br/>
                <p>Please confirm that you wish to participate in this study.</p>
                <p>By clicking the “Yes” button below, you certify that you have read and understood the above information, 
                    that you meet the various selection criteria, that your questions have been answered satisfactorily, 
                    and that you have been advised that you are free to withdraw from this research at any time, without consequence.
                </p> */}
                
            </div>
    );
}











