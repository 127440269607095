import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
// Custom hooks
import useWindowDimensions from '../useWindowDimensions';


const Faceting = ({data, selectedCategory1, selectedCategory2, width, height}) => {
    // Ref to hold the SVG container
    const chartContainer = useRef(null);

    // constants
    const marginLeft = 60;
    const marginRight = 60;
    const marginTop = 40;
    const marginBottom = 60;
    //const { width, height } = useWindowDimensions();
    // const width = 768;
    // const height = 531;
    const maxExponent = Math.floor(Math.log10(d3.max(data, d => parseFloat(d.Value))));
    const minExponent = Math.floor(Math.log10(d3.min(data, d => parseFloat(d.Value))));
    const selectedColor = "#F85741";
    const evenBackgroundColor = "#ffffff";  // Background color for even exponents
    const oddBackgroundColor = "#f0f0f0";  // Background color for odd exponents

    // Group data by exponent
    const dataByExponent = d3.group(data, d => getExponent(d["Value"]));

    // Calculate total facets
    const facetCount = dataByExponent.size;
    const facetPadding = 10;  // Define padding between facets

    // Calculate facet height based on the number of facets and padding
    const facetHeight = (height - marginTop - marginBottom - (facetCount - 1) * facetPadding) / facetCount;

    // Specify categories to change color
    const category1 = data.find(category => category.Category === selectedCategory1);
    const category2 = data.find(category => category.Category === selectedCategory2);
    const categoriesToHighlight = [];
    if(category1 != undefined) categoriesToHighlight.push(category1);
    if(category2 != undefined) categoriesToHighlight.push(category2);


    useEffect(() => {
        // Function to update the chart when data or visual variables or digits change
        if (data === undefined) return;
        updateChart();
    }, [data]);

    // Functions to calculate exponent and mantissa
    function getExponent(value) {
        if (isNaN(value) || value <= 0) return NaN; // Handle invalid values
        return Math.floor(Math.log10(value));
    }
    
    function getMantissa(value) {
        return value / Math.pow(10, getExponent(value));
    }

    const scaleFacet = (v, exponent) => {
        const value = v * 10**exponent;
        let num, unit;
        if(value >= 1000 && value <1000000){
          num = parseInt(value/1000)
          unit = "k"
        } else if (value >= 1000000 && value <1000000000){
          num = parseInt(value/1000000)
          unit = "M"
        } else if (value >= 1000000000 && value <1000000000000){
          num = parseInt(value/1000000000)
          unit = "B"
        } else {
          num = value;
          unit = "";
        }
        return num + unit;
    }


    // Function to update the D3 chart
    const updateChart = () => {
    
        // Define x and y scales
        const x = d3.scaleBand()
        .domain(data.map(d => d.Category).sort())
        // .domain((d3.shuffle(data.map(d => d.Category))))
        .range([marginLeft, width - marginRight])
        .padding(0.5);

        const y = d3.scaleLinear()
        .domain([0, 10])  // Mantissa is always between 0 and 1
        .range([facetHeight, 0]);

        // Select the SVG container
        const svg = d3.select(chartContainer.current);

        svg.attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto; max-height:100%; min-width: 100%; min-height:100%");

        // Remove existing elements to avoid duplication
        svg.selectAll('*').remove();

        // Define the arrow marker
        svg.append("defs").append("marker")
        .attr("id", "arrow")
        .attr("viewBox", "0 0 10 10")
        .attr("refX", 5)
        .attr("refY", 5)
        .attr("markerWidth", 6)
        .attr("markerHeight", 6)
        .attr("orient", "auto")
        .append("path")
        .attr("d", "M 0 0 L 10 5 L 0 10 z")
        .attr("fill", selectedColor);

        const xAxis = svg.append("g")
        .attr("transform", `translate(0,${height - marginBottom})`)
        .call(d3.axisBottom(x));

        // Render each facet
        Array.from(dataByExponent.entries()).reverse().forEach(([exponent, group], index) => {
        const backgroundColor = (exponent % 2 === 0) ? evenBackgroundColor : oddBackgroundColor;
        console.log("exp: ", exponent)

        const g = svg.append("g")
            .attr("transform", `translate(0,${marginTop + index * (facetHeight + facetPadding)})`); 
        
        // Add a background rectangle for each facet
        g.append("rect")
            .attr("transform", `translate(${marginLeft},0)`)
            .attr("width", width - marginLeft - marginRight)
            .attr("height", facetHeight)
            .attr("fill", backgroundColor)
            .attr("stroke", "#ccc");  

        g.append("g")
            .attr("transform", `translate(0,${facetHeight})`)
            .call(d3.axisBottom(x).tickSize(0).tickFormat(""));

        g.append("g")
            .call(d3.axisTop(x).tickSize(0).tickFormat(""));

        // Add horizontal grid lines - thin
        g.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(d3.axisLeft(y)
                .tickValues([2.5, 5, 7.5])
                .tickSize(-(width - marginLeft - marginRight))
                .tickFormat('')
            ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

        // All ticks left axis.
        // g.append("g")
        //     .attr("transform", `translate(${marginLeft},0)`)
        //     .call(d3.axisLeft(y).tickValues([2,3,4,6,7,8,9]).tickFormat("").tickSize(4))
        //     .call(g => g.select(".domain").remove())

            // Strong text left axis.
        g.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(d3.axisLeft(y)
                    .tickValues([10])
                    .tickFormat(d=> scaleFacet(d, exponent))
                    .tickSize(4))
            .selectAll(".tick text")
            .classed("strongText", true)

        // Light text left axis.
        g.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(d3.axisLeft(y).tickValues([5]).tickFormat(d=> scaleFacet(d, exponent)).tickSize(4))
                .selectAll(".tick text")
                .classed("lightText", true)
            .call(g => g.select(".domain").remove())

        if(exponent === minExponent){
            // zero at the bottom of the visualization
            g.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            .call(d3.axisLeft(y).tickValues([0]).tickFormat(d=> scaleFacet(d, exponent)).tickSize(4))
                .selectAll(".tick text")
                .classed("lightText", true)
            .call(g => g.select(".domain").remove())

            g.append("g")
            .attr("transform", `translate(${width - marginRight},0)`)
            .call(d3.axisRight(y).tickValues([0]).tickFormat(d=> scaleFacet(d, exponent)).tickSize(4))
                .selectAll(".tick text")
                .classed("lightText", true)
            .call(g => g.select(".domain").remove())
        }

        // All ticks right axis.
        // g.append("g")
        //     .attr("transform", `translate(${width - marginRight},0)`)   
        //     .call(d3.axisRight(y).tickValues([2,3,4,6,7,8,9]).tickFormat("").tickSize(4))
        //     .call(g => g.select(".domain").remove())

            // Strong text right axis.
        g.append("g")           
            .attr("transform", `translate(${width - marginRight},0)`)
            .call(d3.axisRight(y)
                    .tickValues([10])
                    .tickFormat(d=> scaleFacet(d, exponent))
                    .tickSize(4))
            .selectAll(".tick text")
            .classed("strongText", true) 

        // Light text right axis.
        g.append("g")
            .attr("transform", `translate(${width - marginRight},0)`)
            .call(d3.axisRight(y).tickValues([5]).tickFormat(d=> scaleFacet(d, exponent)).tickSize(4))
                .selectAll(".tick text")
                .classed("lightText", true)
            .call(g => g.select(".domain").remove())



        g.selectAll(".bar")
            .data(group)
            .enter()
            .append("rect")
            .attr("x", d => x(d.Category))
            .attr("y", d => y(getMantissa(d["Value"])))
            .attr("width", x.bandwidth())
            .attr("height", d => facetHeight - y(getMantissa(d["Value"])))
            .attr("fill", "#14164C");

        
        })

        // Add dashed vertical lines
        svg.append("g")
            .attr("class", "dashed-lines")
            .selectAll("line")
            .data(data)
            .enter().append("line")
            .attr("class", "dashed-line")
            .attr("x1", d => x(d.Category) + x.bandwidth() / 2)
            .attr("y1", height - marginBottom)
            .attr("x2", d => x(d.Category) + x.bandwidth() / 2)
            .attr("y2", d => ((maxExponent+1 - getExponent(d["Value"])) * (facetHeight + facetPadding))  + marginTop - facetPadding);

        // Change color of specific categories on x-axis
        xAxis.selectAll(".tick text")
        .style("font-size","14px")
        .style("fill", function(d) {
            return categoriesToHighlight.some(c => c.Category === d) ? selectedColor : "black";
        })
        .style("font-weight", function(d) {
            return categoriesToHighlight.some(c => c.Category === d) ? "bold" : "normal";
        });

        // Add y-axis label
        svg.append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop - 15)
        .attr("text-anchor", "end")
        .text("Value")
        .attr("font-size", "14px");

        // Add legend for units explanation
        svg.append("text")
            .attr("x", marginLeft)
            .attr("y", height - 5)  // Position it above the bottom margin
            .attr("text-anchor", "start")
            .text("k = Thousand (1,000),  M = Million (1,000,000),  B = Billion (1,000,000,000)")
            .attr("alignment-baseline", "start")
            .attr("font-size", "14px");

        // Draw arrows pointing to the highlighted ticks
        categoriesToHighlight.forEach(c => {
            if(c.Category == "") return;
            const xPosition = x(c.Category) + x.bandwidth() / 2;
            const yPositionBottom = height-marginBottom/2; // Position below the x-axis
            const yPositionTop = y(getMantissa(c.Value)) + ((maxExponent - getExponent(c["Value"])) * (facetHeight + facetPadding))  + marginTop ; // Position on top of bar

            svg.append("line")
            .attr("x1", xPosition)
            .attr("y1", yPositionBottom ) 
            .attr("x2", xPosition)
            .attr("y2", yPositionBottom  - 5) 
            .attr("stroke", selectedColor)
            .attr("stroke-width", 2)
            .attr("marker-end", "url(#arrow)");

            // Calculate the midpoint for rotation
            const xMidpoint = xPosition;
            const yMidpoint = yPositionTop - 20; // Midpoint of the line
    
            svg.append("line")
                .attr("x1", xPosition)
                .attr("y1", yPositionTop) 
                .attr("x2", xPosition)
                .attr("y2", yPositionTop - 30) 
                .attr("stroke", selectedColor)
                .attr("stroke-width", 2)
                .attr("marker-end", "url(#arrow)")
                .attr("transform", `rotate(180, ${xMidpoint}, ${yMidpoint})`);

        });

    };

    // Render the SVG container
    return <svg ref={chartContainer}></svg>;
};

export default Faceting;