export const eplusm = [
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/eplusm-start.svg', 
        title:"The Visualization (1/6)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/eplusm-axis.svg', 
        title:"The Visualization (1/6)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/eplusm-scale.gif', 
        title:"The Visualization (2/6)", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/eplusm-inside-1.svg', 
        title:"The Visualization (3/6)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/eplusm-inside-2.svg', 
        title:"The Visualization (4/6)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/eplusm-inside-3.svg', 
        title:"The Visualization (5/6)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/eplusm-inside-4.svg', 
        title:"The Visualization (6/6)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen1.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen2.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen3.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen4.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen5.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen6.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen7.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen8.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen9.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen10.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen11.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/eplusm/screen12.svg', 
        title:"The Experiment", 
        
    },
]