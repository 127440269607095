import React, { useEffect, useState } from 'react';


// Single Step Component
export default function NumeracyTest({updateNumeracyTest}) {

    const handleResponse = (e) => {
        updateNumeracyTest(e.target.name, parseInt(e.target.value))
    }

    return (
        <div>
            <h1>Subjective Numeracy Test:</h1>
            <hr/>
            <p>Welcome to the first part of our study, which focuses on subjective numeracy. 
                In this section, we ask you to assess your own ability with various mathematical tasks. 
                Your honest responses are crucial.
                 By being truthful, you help us improve how we communicate large numbers to diverse audiences, 
                 regardless of their background or level of numeracy. Please answer the following questions:</p>
                 <br/>
            <p><b>How good are you at working with fractions?</b></p>
            <table className='likert-scale horizontal'>
                <tbody>
                    <tr>
                        <th></th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>Not good at all</td>
                        <td><input type="radio" onChange={handleResponse} name="fractions" value="1"/></td>
                        <td><input type="radio" onChange={handleResponse} name="fractions" value="2"/></td>
                        <td><input type="radio" onChange={handleResponse} name="fractions" value="3"/></td>
                        <td><input type="radio" onChange={handleResponse} name="fractions" value="4"/></td>
                        <td><input type="radio" onChange={handleResponse} name="fractions" value="5"/></td>
                        <td><input type="radio" onChange={handleResponse} name="fractions" value="6"/></td>
                        <td>Extremely good</td>
                    </tr>
                </tbody>
            </table>
            <br/>

            <p><b>How good are you at figuring out how much a shirt will cost if it is 25% off?</b></p>
            <table className='likert-scale horizontal'>
                <tbody>
                    <tr>
                        <th></th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>Not good at all</td>
                        <td><input type="radio" onChange={handleResponse} name="shirt" value="1"/></td>
                        <td><input type="radio" onChange={handleResponse} name="shirt" value="2"/></td>
                        <td><input type="radio" onChange={handleResponse} name="shirt" value="3"/></td>
                        <td><input type="radio" onChange={handleResponse} name="shirt" value="4"/></td>
                        <td><input type="radio" onChange={handleResponse} name="shirt" value="5"/></td>
                        <td><input type="radio" onChange={handleResponse} name="shirt" value="6"/></td>
                        <td>Extremely good</td>
                    </tr>
                </tbody>
            </table>
            <br/>

            <p><b>How often do you read logarithmic charts?</b></p>
            <table className='likert-scale horizontal'>
                <tbody>
                    <tr>
                        <th></th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>Never</td>
                        <td><input type="radio" onChange={handleResponse} name="log" value="1"/></td>
                        <td><input type="radio" onChange={handleResponse} name="log" value="2"/></td>
                        <td><input type="radio" onChange={handleResponse} name="log" value="3"/></td>
                        <td><input type="radio" onChange={handleResponse} name="log" value="4"/></td>
                        <td><input type="radio" onChange={handleResponse} name="log" value="5"/></td>
                        <td><input type="radio" onChange={handleResponse} name="log" value="6"/></td>
                        <td>Very often</td>
                    </tr>
                </tbody>
            </table>
            <br/>

            <p><b>How often do you find numerical information to be useful?</b></p>
            <table className='likert-scale horizontal'>
                <tbody>
                    <tr>
                        <th></th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>Never</td>
                        <td><input type="radio" onChange={handleResponse} name="usefulness" value="1"/></td>
                        <td><input type="radio" onChange={handleResponse} name="usefulness" value="2"/></td>
                        <td><input type="radio" onChange={handleResponse} name="usefulness" value="3"/></td>
                        <td><input type="radio" onChange={handleResponse} name="usefulness" value="4"/></td>
                        <td><input type="radio" onChange={handleResponse} name="usefulness" value="5"/></td>
                        <td><input type="radio" onChange={handleResponse} name="usefulness" value="6"/></td>
                        <td>Very often</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <p><b>How often do you travel to the planet Mars to buy kiwis?</b></p>
            <table className='likert-scale horizontal'>
                <tbody>
                    <tr>
                        <th></th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>Never</td>
                        <td><input type="radio" onChange={handleResponse} name="attention" value="1"/></td>
                        <td><input type="radio" onChange={handleResponse} name="attention" value="2"/></td>
                        <td><input type="radio" onChange={handleResponse} name="attention" value="3"/></td>
                        <td><input type="radio" onChange={handleResponse} name="attention" value="4"/></td>
                        <td><input type="radio" onChange={handleResponse} name="attention" value="5"/></td>
                        <td><input type="radio" onChange={handleResponse} name="attention" value="6"/></td>
                        <td>Very often</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}











