import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
// Custom hooks
import useWindowDimensions from '../useWindowDimensions';


const DesignOne = ({data, selectedCategory1, selectedCategory2}) => {
  // Ref to hold the SVG container
  const chartContainer = useRef(null);

  // constants
  const marginLeft = 100;
  const marginRight = 100;
  const marginTop = 30;
  const marginBottom = 60;
  const { width, height } = useWindowDimensions();
  const maxExponent = 7;
  const minExponent = 0;
  const selectedColor = "#F85741";
  const numberOfExponents = maxExponent + 1 - minExponent;

  const numberOfTicksInFacet = 9;
  const eplusmTickValues = [0.45, 1.45, 2.45, 3.45, 4.45, 5.45, 6.45, 7.45]

  function round(number) {
    const fractionalPart = number % 1; // Get the fractional part of the number
    const integerPart = Math.floor(number); // Get the integer part of the number
  
    if (fractionalPart > 0.5) {
      return Math.ceil(number); // Round up
    } else {
      return Math.floor(number); // Round down
    }
  }

  const scaleExpPlusMant = (value, base) => {
    const exp = Math.trunc(value);
    const mant = (base/(base - 1) * (value - exp));
    const scale = (mant != 0) ? mant * base ** (exp + 1) : base ** exp;
    return d3.format("~s")(Math.round(scale * 100) / 100);  // correcting for rounding errors
  }


  useEffect(() => {
    // Function to update the chart when data or visual variables or digits change
    if (data === undefined) return;
    updateChart();
  }, [data]);

  const getExponent = (value, base) => {
    // To calculate the base-1000 logarithm using Math.log(value), 
    // you would need to adjust the formula to convert from the natural logarithm to the base-1000 logarithm.
    const baseLog = Math.log(value) / Math.log(base);
    return Math.trunc(baseLog);
  }

  const getMantissa = (value, base) => {
    // To calculate the base-1000 logarithm using Math.log(value), 
    // you would need to adjust the formula to convert from the natural logarithm to the base-1000 logarithm.
    const baseLog = Math.log(value) / Math.log(base);
    return value / (base ** Math.floor(baseLog));
  }


  // Function to update the D3 chart
  const updateChart = () => {
    const facetHeight = (height - marginTop - marginBottom)/numberOfExponents;
    const unitSpace = facetHeight / numberOfTicksInFacet;
    const spaceBetweenUnits = 3; // necessary to have visual space between the units
    let unitHeight;
    if(unitSpace <= 2){
      unitHeight = 0;
      console.log("Not sufficient size display")
    } else {
      unitHeight = unitSpace - spaceBetweenUnits;
    }
    
    // Custom tick values for the y-axis
    const expTicks = [];
    for (let exp = 0; exp <= maxExponent + 1; exp++) {
        expTicks.push(exp);
    }
  
    // Custom tick values for the y-axis
    const ticks = [];
    for (let exp = 0; exp <= maxExponent + 1; exp++) {
      for (let m = 1; m < 10; m++){
        ticks.push(exp + (m - 1)/(10 - 1));
      }
    }
    // const allTicks = ticks.filter(t => t >= minExponent && t <= maxExponent);
    const allTicks = ticks.filter(t => t >= minExponent && t <= maxExponent + 1);
  
    // Declare the x (horizontal position) scale.
    const x = d3.scaleBand()
        .domain(d3.shuffle(data.map(d => d.Category))) // Shuffle the order randomly
        .range([marginLeft, width - marginRight])
        .padding(0.4);
  
    const bandWidthExp = x.bandwidth()/(numberOfExponents); // to change the width based on the number of exponents
    //const bandWidthExp = x.bandwidth(); // to change the width based on the number of exponents


    // Declare the y (vertical position) scale.
    const y = d3.scaleLinear()
    .domain([minExponent, maxExponent + 1])
    .range([height - marginBottom, marginTop]);


    // Select the SVG container
    const svg = d3.select(chartContainer.current);

    svg.attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; max-height:100%");

    // Remove existing elements to avoid duplication
    svg.selectAll('*').remove();

    // Define the arrow marker
    svg.append("defs").append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 5)
      .attr("refY", 5)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 z")
      .attr("fill", selectedColor);

    // Add horizontal grid lines - thin
    svg.append("g")
    .attr("class", "grid")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(eplusmTickValues)
        .tickSize(-width + marginLeft + marginRight)
        .tickFormat('')
    ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

    // Add horizontal grid lines - thick
    svg.append("g")
    .attr("class", "grid-strong")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(expTicks )
        .tickSize(-width + marginLeft + marginRight)
        .tickFormat('')
    ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

    // Create background for units
    svg.selectAll(".unit-bar-background")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "unit-bar-background")
    .attr("transform", (d, i) => `translate(${x(d.Category)}, 0)`)
    .each(function (d, i) {
    const barGroup = d3.select(this);
    // Call the createWaffleChart function to create the waffle chart
    const totalUnits = 10;
    let expPos = getExponent(d["Value"], 10);
    let mantissaUnits = round(getMantissa(d["Value"], 10));
    if(mantissaUnits > 9) {
    //if by rounding we reach the next exponent go to the next one
    expPos = getExponent(d["Value"], 10) + 1;
    } 
    createUnits(totalUnits, x(d.Category), y(expPos), "#DDDDE3",expPos);
    });

    // Create unit bar charts
    svg.selectAll(".unit-bar")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "unit-bar")
    .attr("transform", (d, i) => `translate(${x(d.Category)}, 0)`)
    .each(function (d, i) {
    const unitBarGroup = d3.select(this);
    // Call the createWaffleChart function to create the waffle chart
    let numUnits = round(getMantissa(d["Value"], 10)); //num units equal to mantissa units
    let expPos = getExponent(d["Value"], 10);
    if(numUnits > 9) {
    //if by rounding we reach the next exponent go to the next one
    expPos = getExponent(d["Value"], 10) + 1;
    numUnits = 1;
    } 
    createUnits(numUnits, x(d.Category), y(expPos), "#14164C", expPos);
    });

    // Define a function to create the waffle chart within each bar
    function createUnits(numberOfDataPoints, xPos, yPos, color, expPos) {
    // Define the waffle chart dimensions and parameters (as in your previous waffle chart)
    const data = new Array(numberOfDataPoints).fill(0);
    const columns = 1;
    //const rows = Math.ceil(numberOfDataPoints / columns);

    // Create the waffle chart within the same SVG
    const waffleChartGroup = svg.append("g")
    .attr("transform", `translate(${xPos}, 0)`);

    // Generate the waffle chart by creating individual squares
    waffleChartGroup.selectAll(".square")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "square")
    .attr("width", bandWidthExp * (expPos+1)) // to change the width based on the number of exponents
    .attr("height", unitHeight)
    .attr("x", (d, i) => ((i % columns) * unitHeight) + (x.bandwidth() - bandWidthExp * (expPos+1)) / 2) // Centering the bar
    .attr("y", (d, i) => unitHeight/2 + spaceBetweenUnits + yPos - (i + 1) * (unitHeight + spaceBetweenUnits)) // Calculate from bottom to top
    .style("fill", color);
    }


    // Add dashed vertical lines
    svg.append("g")
    .attr("class", "dashed-lines")
    .selectAll("line")
    .data(data)
    .enter().append("line")
    .attr("class", "dashed-line")
    .attr("x1", d => x(d.Category) + x.bandwidth() / 2)
    .attr("y1", height - marginBottom)
    .attr("x2", d => x(d.Category) + x.bandwidth() / 2)
    .attr("y2", d => y(round(getMantissa(d["Value"], 10)) > 9 ? getExponent(d["Value"], 10) + 1 : getExponent(d["Value"], 10)) + unitHeight/2);

    // Add the x-axis and label.
    const xAxis = svg.append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x).tickSizeOuter(0));


    // Strong text left axis.
    svg.append("g")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(expTicks)
        .tickFormat(d=>scaleExpPlusMant(d, 10))
        .tickSize(4))
    .selectAll(".tick text")
    .classed("strongText", true)

    // Light text left axis.
    svg.append("g")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y).tickValues(eplusmTickValues).tickFormat(d=>scaleExpPlusMant(d, 10)).tickSize(4))
        .selectAll(".tick text")
        .classed("lightText", true)
    .call(g => g.select(".domain").remove())

    // All ticks left axis.
    svg.append("g")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y).tickValues(allTicks).tickFormat("").tickSize(4))
    .call(g => g.select(".domain").remove())

    // Strong text right axis.
    svg.append("g")
    .attr("transform", `translate(${width - marginRight},0)`)
    .call(d3.axisRight(y)
        .tickValues(expTicks)
        .tickFormat(d=>scaleExpPlusMant(d, 10))
        .tickSize(4))
    .selectAll(".tick text")
    .classed("strongText", true)

    // Light text right axis.
    svg.append("g")
    .attr("transform", `translate(${width - marginRight},0)`)
    .call(d3.axisRight(y).tickValues(eplusmTickValues).tickFormat(d=>scaleExpPlusMant(d, 10)).tickSize(4))
        .selectAll(".tick text")
        .classed("lightText", true)
    .call(g => g.select(".domain").remove())

    // All ticks right axis.
    svg.append("g")
    .attr("transform", `translate(${width - marginRight},0)`)
    .call(d3.axisRight(y).tickValues(allTicks).tickFormat("").tickSize(4))
    .call(g => g.select(".domain").remove())


    // Specify categories to change color
    const categoriesToHighlight = [selectedCategory1, selectedCategory2];

    // Change color of specific categories on x-axis
    xAxis.selectAll(".tick text")
        .style("fill", function(d) {
            return categoriesToHighlight.includes(d) ? selectedColor : "black";
        })
        .style("font-weight", function(d) {
            return categoriesToHighlight.includes(d) ? "bold" : "normal";
        });

    // Add y-axis label
    svg.append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop - 10)
        .attr("text-anchor", "end")
        .text("Value")
        .attr("font-size", "14px");

      // Add legend for units explanation
    svg.append("text")
        .attr("x", marginLeft)
        .attr("y", height - 5)  // Position it above the bottom margin
        .attr("text-anchor", "start")
        .text("k = Thousands,  M = Millions,  G = Billions")
        .attr("alignment-baseline", "start")
        .attr("font-size", "14px");

    // Draw arrows pointing to the highlighted ticks
    categoriesToHighlight.forEach(category => {
        if(category == "") return;
        const xPosition = x(category) + x.bandwidth() / 2;
        const yPosition = height-marginBottom/2; // Position below the x-axis

        svg.append("line")
          .attr("x1", xPosition)
          .attr("y1", yPosition) 
          .attr("x2", xPosition)
          .attr("y2", yPosition - 5) 
          .attr("stroke", selectedColor)
          .attr("stroke-width", 2)
          .attr("marker-end", "url(#arrow)");
    });
    
  };

  // Render the SVG container
  return <svg ref={chartContainer}></svg>;
};

export default DesignOne;