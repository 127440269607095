import React, { useEffect, useState } from 'react';
// icon
import { FaArrowRight } from "react-icons/fa6";

// components
import WelcomeScreen from './WelcomeScreen';
import ConsentForm from './ConsentForm';
import NumeracyTest from './NumeracyTest';
import Instructions from './Instructions';



// Single Step Component
export default function InitialPage({trialID, nextTrial, numeracyTest, updateNumeracyTest, instructionStart, training, visualizationDesign}) {
    const [next, setNext] = useState(false); // to move to consent
    const [agree, setAgree] = useState(true); // to stop the experiment if disagree with consent form
    const [toNumeracyTest, setToNumeracyTest] = useState(false); // move to numeracy test
    const [toInstructions, setToInstructions] = useState(false); // move to experiment instructions

    const handleClick = () => {
        window.location.href = "https://app.prolific.com/submissions/complete?cc=C7CB643K";
    };


    const handleNext = (e) => {
        setNext(true);
    }

    const handleAgree = (e) => {
        setAgree(false);
    }

    const moveToNumeracyTest = (e) => {
        setToNumeracyTest(true);
    }

    const moveToInstructions = (e) => {
        instructionStart();
        setToInstructions(true);
    }



    return (
        <div className="welcome">
            { (!next && agree && !toNumeracyTest && !toInstructions) &&
            <div className="info-page">
                <WelcomeScreen />
                <button 
                className="large" 
                onClick={handleNext} 
                >Next&nbsp;<FaArrowRight />
                </button>
            </div>
            }
            {(next && agree && !toNumeracyTest && !toInstructions) &&
            <div className="info-page">
                <ConsentForm />
                <div className="group-buttons">
                    <button 
                    className="large no-button" 
                    onClick={handleAgree} 
                    >No, I don't agree.
                    </button>
                    <button 
                    className="large" 
                    onClick={moveToNumeracyTest} 
                    >Yes, I agree! Start the experiment!&nbsp;<FaArrowRight/>
                    </button>
                </div>
            </div>
        }
        {(next && agree  && toNumeracyTest && !toInstructions) &&
            <div className="info-page">
                <NumeracyTest updateNumeracyTest={updateNumeracyTest}/>
                <div className="group-buttons">
                    {Object.values(numeracyTest).some(value => value === 0)  && <small style={{marginTop: 30, marginRight: 20}}>Provide an answer to all questions to proceed.</small>}
                    <button 
                    className={`large ${Object.values(numeracyTest).some(value => value === 0) ? 'disabled' : 'enabled'}`}  
                    disabled={Object.values(numeracyTest).some(value => value === 0)}
                    onClick={moveToInstructions} 
                    > Next&nbsp;<FaArrowRight/>
                    </button>
                </div>
            </div>
        }
        {(next && agree  && toNumeracyTest && toInstructions) &&
            <Instructions nextTrial={nextTrial} trialID={trialID} training={training} visualizationDesign={visualizationDesign}/>
        }
        {!agree &&
            <div className='center'>
                <p>We are deeply sorry that you do not agree with the terms of this study. <br/>
                Unfortunately, you cannot participate without agreeing to them. Therefore, you can close the window. <br/>
                Thank you for the interest! Have a great day!</p>
                <button 
                    className="large" 
                    onClick={handleClick} 
                    >Go back to Prolif
                </button>
            </div>
        }
              
        </div>
    );
}











