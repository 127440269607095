export const lin = [
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/lin-start.svg', 
        title:"The Visualization (1/3)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/lin-explanation.svg', 
        title:"The Visualization (2/3)", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/lin-explanation-2.svg', 
        title:"The Visualization (3/3)", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen1.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen2.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen3.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen4.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen5.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen6.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen7.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen8.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen9.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen10.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen11.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/lin/screen12.svg', 
        title:"The Experiment", 
        
    },
]