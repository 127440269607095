export const log = [
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/log-start.svg', 
        title:"The Visualization (1/5)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/log-axis.svg', 
        title:"The Visualization (2/5)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/log-scale.gif', 
        title:"The Visualization (3/5)", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/log-inside-1.svg', 
        title:"The Visualization (4/5)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/log-inside-2.svg', 
        title:"The Visualization (5/5)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen1.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen2.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen3.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen4.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen5.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen6.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen7.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen8.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen9.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen10.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen11.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/log/screen12.svg', 
        title:"The Experiment", 
        
    },
]