import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Style
import './App.scss';

// Components
import Step from './components/Step.js';
import InitialPage from './components/InitialPage';
import FinalPage from './components/FinalPage';
import BlockScreen from './components/BlockScreen';

// Methods
import {numberToWords, findStars} from './utils'

// icon
import { FaArrowRight } from "react-icons/fa6";



function Main() {
  
// setup
  const [showWarning, setShowWarning] = useState(false);
  const [isWindowFocused, setIsWindowFocused] = useState(true);
  const [trialID, setTrialID] = useState(0);
  const [participantID, setParticipantID] = useState(null);
  const [blockID, setBlockID] = useState(0);
  const [condition, setCondition] = useState("");
  const [visualizationDesign, setVisualizationDesign] = useState("");
  const [training, setTraining] = useState(null);
  const [experimentalSetup, setExperimentalSetup] = useState([]);
  const [currentTrialSetup, setCurrentTrialSetup] = useState();
  const [blockScreen, setBlockScreen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(1);
  const [numericalResponse, setNumericalResponse] = useState("");
  const [category1Value, setCategory1Value] = useState();
  const [category2Value, setCategory2Value] = useState();
  const lastTrialsinBlock = [4, 16]

// attention checks
const [attentionChecks, setAttentionChecks] = useState({attentionNumeracy: null, attentionBlock1: null, attentionBlock2: null})

// numeracy
const [numeracyTest, setNumeracyTest] = useState({"fractions": 0, "shirt":0, "log":0, "usefulness":0, "attention":0})

// logs
const [displayValue, setDisplayValue] = useState(false)
const [dataLog, setDataLog] = useState([]); // log for each trial
const [response, setResponse] = useState("");
const [submitResponse, setSubmitResponse] = useState(false);
const [correctAnswer, setCorrectAnswer] = useState();
const [responseText, setResponseText] = useState("");
const [confidence, setConfidence] = useState("");
const [feedback, setFeedback] = useState("");
const [experience, setExperience] = useState(0);
const [theEnd, setTheEnd] = useState(false);
const [countZoom, setCountZoom] = useState(0);
const [unfocusedWindow, setCountFocused] = useState(0);
const [zoomPCLevel, setZoomPCLevel] = useState(window.devicePixelRatio);
const [screenWidth, setScreenWidth] = useState(window.screen.width);
const [screenHeight, setScreenHeight] = useState(window.screen.height);
const [starsTrial, setStarsTrial] = useState(0);

// time
  const [startTimeExperiment, setStartTimeExperiment] = useState(0);
  const [startTimeInstructions, setStartTimeInstructions] = useState(0);
  const [durationInstructions, setDurationInstructions] = useState(0);
  const [startTimeTrial, setStartTimeTrial] = useState(0);
  const [fisrtClickTime, setFirstClickTime] = useState(0);
  const [finalResponseTime, setFinalResponseTime] = useState(0);
  const [submitTime, setSubmitTime] = useState(0);
  const [timeStamps, setTimeStamps] = useState({})
  const timeForTrial = 90;
  const timeToWait = 5;
  const [timeLeft, setTimeLeft] = useState(timeForTrial); // Initialize the timer state to timeForTrial seconds (1 minute)
  const totalTrials = 28;

// stars
  const [starsBlockValue, setStarsBlockValue] = useState(0);
  const [starsBlockDifference, setStarsBlockDifference] = useState(0);
  const [starsBlockRatio, setStarsBlockRatio] = useState(0);
  const [totalStars, setTotalStars] = useState(0);

// Prolific parameters
// example: https://test.com?PROLIFIC_PID=5a9d64f5f6dfdd0001eaa73d
// https://aviz-studies.lisn.upsaclay.fr/omvis/?PROLIFIC_PID={{%PROLIFIC_PID%}}&STUDY_ID={{%STUDY_ID%}}&SESSION_ID={{%SESSION_ID%}}
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
let prolificPid = queryParams.get('PROLIFIC_PID');
if (prolificPid == null) {
    prolificPid = "unknown";
}
let studyId = queryParams.get('STUDY_ID');
if (studyId == null) {
    studyId = "unknown";
}
let sessionID = queryParams.get('SESSION_ID');
if (sessionID  == null) {
    sessionID  = "unknown";
}
let viz = queryParams.get('viz');
if (viz == null) {
    viz = "ssb";
}


    // DEVEOPEMENT: initial rendering to fetch the participant number and the condition
    // Comment for production
    useEffect(() => {
          setStartTimeExperiment(Date.now());
          setTimeStamps((prevState) => ({
            ...prevState,
            "startExperiment": Date.now()
          }));
          //random assignment of participant id, this indicate the id of the experimental setup
          const randomNumber = Math.floor(Math.random() * 500) + 1;
          console.log("participantID", randomNumber);
          if (participantID === null || participantID === undefined) {
            // setParticipantID(randomNumber);
            setParticipantID(1);
          }
          

          setVisualizationDesign(viz)
          setTraining(true);
          console.log("prolificPid: ", prolificPid)
    }, []);


  // PRODUCTION: initial rendering to fetch the participant number and the condition
  // Comment for developement
  // useEffect(() => {
  //   setStartTimeExperiment(Date.now());
  //   const fetchParticipantID = async () => {
  //     try {
  //       const response = await fetch(`${process.env.PUBLIC_URL}/php/incrementID.php`);
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       const id = data.participantNumber;
  //       setParticipantID(id);
  //     } catch (error) {
  //       console.error('Error fetching participant ID:', error);
  //     }
  //   };

  //   const fetchCondition = async () => {
  //     try {
  //       const response = await fetch(`${process.env.PUBLIC_URL}/php/assignCondition.php`);
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       setCondition(data.condition)
  //       // Split the condition name by '-'
  //       const parts = data.condition.split('-');
        
  //       // // The design is the first part
  //       // const design = parts[0];
  //       // setVisualizationDesign(design)

  //       // // Check for training status
  //       // const training = parts.includes('no') ? false : true;
  //       // setTraining(training);

  //       setVisualizationDesign(viz)
  //       setTraining(true);

  //     } catch (error) {
  //       console.error('Error fetching condition:', error);
  //     }
  //   };

  //   //Execute the fetch functions
  //   fetchParticipantID();
  //   fetchCondition();
  // }, []);
  

    // Second useEffect to fetch the data based on the participant ID
    useEffect(() => {
      if (participantID !== null) {
        fetch(`${process.env.PUBLIC_URL}/data.json`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            const participantData = data.data.find(p => p.participantID === participantID);
            if (participantData) {
              // setVisualizationDesign(participantData.visualizationDesign);
              setExperimentalSetup(participantData.experimental_setup);
            } else {
              throw new Error(`Participant with ID ${participantID} not found`);
            }
          })
          .catch(error => console.error('Error fetching participant data:', error));
      }
    }, [participantID]);

  // Third useEfeect to handle changes in trial id
  useEffect(() => {
    if (experimentalSetup === undefined && trialID > totalTrials) return;
    if (trialID == 1) {
      const end = Date.now();
      setTimeStamps((prevState) => ({
        ...prevState,
        "endInstructions": Date.now()
      }));
      const duration = (end - startTimeInstructions)/1000;
      setDurationInstructions(duration);
      setAttentionChecks(prevState => ({
        ...prevState,
        attentionNumeracy: numeracyTest["attention"] == 1
      }));
    } 
    setResponseText("");
    setConfidence("");
    setNumericalResponse("");
    setSelectedUnit(1);
    setDisplayValue(false);
    setStartTimeTrial(Date.now()); // Set the start time for the new trial
    if(trialID > 0 && trialID <= totalTrials){
      setTimeStamps((prevState) => ({
        ...prevState,
        ["startTrial" + trialID]: Date.now()
      }));
    }
    setFirstClickTime(0);
    setStarsTrial(0);
    setCurrentTrialSetup(experimentalSetup.find(item => parseInt(item.trialID) === parseInt(trialID)));
    setTimeLeft(timeForTrial);
  }, [trialID]);

    // Forth useEffect to handle trial setup
    useEffect(() => {
      if (currentTrialSetup == undefined) return;
      const currentTask = currentTrialSetup.task;
      if(blockID != currentTrialSetup.blockID){
        setBlockID(currentTrialSetup.blockID)
      }
    if (currentTask == "Value") {
      const category = currentTrialSetup.selectedCategory1;
      const actualAnswer = currentTrialSetup.data.find(item => item.Category === category).Value;
      setCategory1Value(actualAnswer);
      setCategory2Value(0);
      console.log("actualAnswer: ", actualAnswer)
      setCorrectAnswer(actualAnswer);
    } else {
      const category1 = currentTrialSetup.selectedCategory1;
      const category2 = currentTrialSetup.selectedCategory2;
      const cat1Value =  currentTrialSetup.data.find(item => item.Category === category1).Value;
      const cat2Value = currentTrialSetup.data.find(item => item.Category === category2).Value;
      setCategory1Value(cat1Value);
      setCategory2Value(cat2Value);
      let actualAnswer;
      if(currentTask ==  "Ratio_Same" ||  currentTask == "Ratio_Neighbour" || currentTask == "Ratio_Distant"){
        const actualAnswerAllDigits = Math.max(...[cat1Value, cat2Value])/Math.min(...[cat1Value, cat2Value])
        actualAnswer = parseFloat(actualAnswerAllDigits.toFixed(1))
      } else {
        actualAnswer = Math.max(...[cat1Value, cat2Value])-Math.min(...[cat1Value, cat2Value])
      }
      console.log("actualAnswer: ", actualAnswer )
      setCorrectAnswer(actualAnswer);
    }

  }, [currentTrialSetup]);

    // Forth useEfeect to handle changes in response when submitted
    useEffect(() => {
      if (currentTrialSetup == undefined) return;
      if (!submitResponse) return;
      const stars = findStars(correctAnswer, response);
      setStarsTrial(stars);
      setTotalStars(prevCounter => prevCounter + stars);
      if (blockID == 1) {
        setStarsBlockValue(prevCounter => prevCounter + stars);
      } else if (blockID == 2) {
        setStarsBlockDifference(prevCounter => prevCounter + stars);
      } else if (blockID == 3) {
        setStarsBlockRatio(prevCounter => prevCounter + stars);
      } else {
        console.log("ERROR: Uknown block id.")
      }
    }, [submitResponse]);



  // Forth useEffect to handle the timer
  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft === 0) {
      if (trialID >= 1 && trialID <= totalTrials){
        saveAndNext();
      }
      return;
    }

    // Save the intervalId to clear the interval when the component unmounts
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear the interval when the component unmounts or when the timeLeft changes
    return () => clearInterval(intervalId);
  }, [timeLeft]);




  // PRODUCTION: Fifth useEffect to handle the data saving at the end of the experiment
  useEffect(() => {
    if (!theEnd) return;
    console.log("The end.")
    const endTimeExp = Date.now();
    const timeSpent = (endTimeExp - startTimeExperiment) / 1000; // Calculate time spent in seconds
    console.log("Experiment duration: ", timeSpent)
    console.log("Instructions duration: ", durationInstructions)
    handleSave();
    //incrementConditionCount(condition);

  }, [theEnd]);


  // check window size
  useEffect(() => {
    // Initial check
    checkFullScreen();

    // Check when the window is resized
    window.addEventListener('resize', checkFullScreen);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkFullScreen);
    };
  }, []);

  // check  window focus
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState !== 'visible') {
        setCountFocused((prevCount) => {
          const newCount = prevCount + 1;
          return newCount;
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  
  const checkFullScreen = () => {
    const tolerance = 150; // Larger tolerance for browser UI elements
    const isFullScreenNow =
      window.innerWidth + tolerance >= window.screen.availWidth &&
      window.innerHeight + tolerance >= window.screen.availHeight;

    const unappropriateScreenDimensions = window.screen.height < 864 || window.screen.width < 1440;

    console.log("unappropriateScreenDimensions", unappropriateScreenDimensions)

    // Check if the zoom level is less than 100%
    const isZoomSmallerThan100 =
    window.innerWidth > window.screen.availWidth ||
    window.innerHeight > window.screen.availHeight;
    setCountZoom(prevCount => (!isFullScreenNow || isZoomSmallerThan100) ? prevCount + 1 : prevCount);
    setShowWarning((!isFullScreenNow || isZoomSmallerThan100) || unappropriateScreenDimensions);
    setScreenWidth(window.screen.width);
    setScreenHeight(window.screen.height);
    setZoomPCLevel(window.devicePixelRatio);
  };



  const handleSave = () => {
    const endTimeExp = Date.now();
    const experimentDuration = (endTimeExp - startTimeExperiment) / 1000; // Calculate time spent in seconds
    fetch(`${process.env.PUBLIC_URL}/php/saveData.php`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          participantID, 
          prolificPid, 
          studyId,
          sessionID,
          visualizationDesign, 
          experimentDuration, 
          durationInstructions,
          experience, 
          feedback, 
          numeracyTest, 
          attentionChecks,
          countZoom,
          unfocusedWindow,
          screenHeight,
          screenWidth,
          zoomPCLevel, 
          timeStamps,
          dataLog }),
    })
    .then(response => response.json())
    .then(result => {
        console.log("toPhp: ", result);
    })
    .catch(error => {
        console.error('Error:', error);
    });
};

  const updateNumeracyTest = (key, value) => {
    setNumeracyTest((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  // update response
  const handleResponse = (value) => {
    if(value < 0 || isNaN(value) || value == ""){
      setResponse("")
    };
    setResponse(value);
    setResponseText(numberToWords(value));
    setFinalResponseTime((Date.now() - startTimeTrial)/1000);
  }

  const handleChangeUnit = (event) => {
    const value = parseInt(event.target.value);
    setSelectedUnit(value);
    if(numericalResponse === ""){
      handleResponse("");
    } else {
      handleResponse(numericalResponse * value);
    }
   
  };

  const firstClick = () => {
    if(fisrtClickTime === 0){
      setFirstClickTime((Date.now() - startTimeTrial)/1000)
      console.log("Click")
    }
    
  };

  const handleChangeNumber = (event) => {
    const value = event.target.value;
    if(value < 0) return;
    if (value === "" || isNaN(value)) {
      setNumericalResponse("");
      handleResponse(""); // Set to an empty string if the value is invalid or NaN
    } else {
      setNumericalResponse(value);
      handleResponse(selectedUnit * value);;
    }
    
  };

  const instructionStart = () => {
    setStartTimeInstructions(Date.now());
    setTimeStamps((prevState) => ({
      ...prevState,
      "startInstructions": Date.now()
    }));
  }

  const handleAttentionCheck = (block, value) => {
    setAttentionChecks(prevState => ({
      ...prevState,
      [block]: value
    }));
  }

  const handleExperience = (e) => {
    const value = parseInt(e.target.value, 10);
    setExperience(value);
  }

  const handleConfidence = (e) => {
    const value = parseInt(e.target.value, 10);
    setConfidence(value);
  };

  const handleFeedback = (e) => {
    setFeedback(e.target.value)
}

  const handleTheEnd = () => {
    setTimeStamps((prevState) => ({
      ...prevState,
      "endExperiment": Date.now()
    }));
    setTheEnd(true);
}

  const handleDisplayValue = () => {
    setDisplayValue(true)
  }

  const nextBlock = () => {
    setBlockScreen(false)
    setTrialID(prevCount => prevCount + 1); 
  }


  // save data logs and go to the next trial
  const submit = () => {
    if(!submitResponse){
         // calculate time
        const endTime = Date.now();
        setSubmitTime(endTime)
        setSubmitResponse(true);
    } else {
        saveAndNext()
    }
  }

  const saveAndNext = () => {
    const endTime = Date.now();
    if(trialID <= totalTrials){
      setTimeStamps((prevState) => ({
        ...prevState,
        ["endTrial" + trialID]: Date.now()
      }));
    }
    const checktime = (endTime - submitTime) / 1000
    // save data
    setDataLog(prevdataLog => [
        ...prevdataLog,
        {
        // participantID: participantID, 
        // visualizationDesign: visualizationDesign,
        task: currentTrialSetup.task,
        trialID: parseInt(trialID), 
        response: response,
        correctAnswer: correctAnswer,
        selectedCategory1: category1Value,
        selectedCategory2: category2Value,
        confidence: confidence,
        fisrtClickTime:fisrtClickTime,
        finalResponseTime:finalResponseTime,
        submitTime: (submitTime - startTimeTrial) / 1000,
        checkTime: checktime,
        stars: starsTrial
        },
    ]);

    // Reset response state
    setResponse("");
    setSubmitResponse(false);

    // move to the next trial
    if(!lastTrialsinBlock.includes(trialID)){
        setTrialID(prevCount => prevCount + 1); 
    } else {
        setBlockScreen(true); 
    }
  }

  const goToNextTrial = (e) => {
     // move to the next trial
     const value = parseInt(e.target.value);
     if (Number.isFinite(Number(value))) {
      setTrialID(value);
    }
  }


  const incrementConditionCount = (condition) => {
    fetch(`${process.env.PUBLIC_URL}/php/incrementConditionCount.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        condition: condition
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        console.log('Condition count incremented successfully');
      } else {
        console.error('Error incrementing condition count:', data.message);
      }
    })
    .catch(error => console.error('Error:', error));
  };




  return (
    <div className="main">
       {showWarning && (
        <div className="overlay">
          <div>
            {screenHeight < 864 || screenWidth < 1440 ?
            <>
            <p><strong>You are seeing this message because your screen resolution is not appropriate for this experiment.</strong></p>
            <p>To ensure a controlled environment for our study, it is important to perform the experiment at the correct screen resolution.</p>
            <p>Thank you for your interest!</p>
            <br/>
            </>
            :
            <>
            <p><strong>You are seeing this message because the application is either not displayed in full-screen mode on your PC or your browser's zoom level is set above 100%.</strong></p>
            <p>If the application is already covering the entire screen, please verify that your browser's zoom level is set to 100%.</p>
            <p>To ensure a controlled environment for our study, it is important to perform the experiment at the correct screen resolution.</p>
            <br/>
            <p>If your browser's zoom level is set to 100% and the browser window is in full screen, it means, unfortunately, that your screen resolution is not appropriate for this experiment.</p>
            </>
            }
         
          </div>
        </div>
      )}
      {participantID === undefined ?
      <p>Loading...</p>
      :
      <>
      { trialID == 0 ? // initial instructions
       <InitialPage 
        trialID={trialID} 
        nextTrial={goToNextTrial}
        numeracyTest={numeracyTest}
        training={training}
        visualizationDesign = {visualizationDesign}
        updateNumeracyTest={updateNumeracyTest}
        instructionStart={instructionStart}
      />
      : (trialID <= totalTrials ?  // trials
      <>
      {(currentTrialSetup != undefined) &&
    <>
      {blockScreen ?
      <BlockScreen 
        blockID = {currentTrialSetup.blockID}
        starsBlockValue = {starsBlockValue}
        starsBlockDifference = {starsBlockDifference}
        starsBlockRatio = {starsBlockRatio}
        totalStars = {totalStars}
        nextBlock={nextBlock}
        handleAttentionCheck={handleAttentionCheck}
      />
      :
      <>
        <Step 
        trialID={trialID} 
        timeLeft={timeLeft}
        visualizationDesign={visualizationDesign} 
        currentTrialSetup={currentTrialSetup} 
        correctAnswer={correctAnswer}
        response={response}
        training={training}
        responseText={responseText}
        submitResponse={submitResponse}
        confidence={confidence}
        selectedUnit={selectedUnit}
        numericalResponse={numericalResponse}
        firstClick={firstClick}
        handleChangeUnit={handleChangeUnit}
        handleChangeNumber={handleChangeNumber}
        handleConfidence={handleConfidence}
        handleResponse={handleResponse}/>
        <div className="buttons">
          <button 
            className={`large ${(timeForTrial-timeLeft < timeToWait) || (((response==="" || isNaN(response))) || (confidence=="" || isNaN(confidence))) ? 'disabled' : 'enabled'}`} 
            value={parseInt(trialID) + 1} 
            onClick={submit} 
            disabled={(timeForTrial-timeLeft < timeToWait) || ((response==="" || isNaN(response)))}
            > {trialID < totalTrials ? (submitResponse ? ( lastTrialsinBlock.includes(trialID) ? "End of Block " + currentTrialSetup.blockID : "Next trial") : "Submit answers") : "Towards the end of the study"} &nbsp;
            {(submitResponse || trialID >= totalTrials) ? <FaArrowRight/> : <></>}
          </button>
          {(timeForTrial-timeLeft < timeToWait)  && <small>You will be able to proceed in {timeLeft - (timeForTrial - timeToWait)}</small>}
          {(timeForTrial-timeLeft >= timeToWait) && ((((response==="" || isNaN(response))) && (confidence=="" || isNaN(confidence)))) && <small>Provide an answer to proceed.</small>}
          {(timeForTrial-timeLeft >= timeToWait) && ((((response==="" || isNaN(response))) && !(confidence=="" || isNaN(confidence)))) && <small>Provide an answer to proceed.</small>}
          {(timeForTrial-timeLeft >= timeToWait) && ((!((response==="" || isNaN(response))) && (confidence=="" || isNaN(confidence)))) && <small>State your confidence level to proceed.</small>}
          <br/>
        </div>
        </>
        }
        </>
        }
        
      </>
      : // end of the experiment
        <FinalPage 
          totalTrials = {totalTrials}
          totalStars={totalStars}
          starsBlockRatio={starsBlockRatio}
          feedback={feedback} 
          experience={experience}
          handleExperience={handleExperience}
          handleFeedback={handleFeedback} 
          theEnd={theEnd} 
          handleTheEnd={handleTheEnd}/>
      ) }
      </>
      }
      </div> 
  );
}

export default Main;
