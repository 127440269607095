// App.js or App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Main from './Main';

function App() {
  return (
    <Router>
      <Routes>
       {/* <Route path="/omvis/" element={<Main />} /> */}
       <Route path="/" element={<Main />} />
      </Routes>
    </Router>
  );
}

export default App;