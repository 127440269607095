// useful methods that can be used from several components

function formatNumber(num) {
  // Convert the number to a string
  const numStr = num.toString();

  // Split the string into integer and decimal parts
  const [integerPart, decimalPart] = numStr.split(".");

  // Use a regular expression to add commas every three digits in the integer part
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // If there's a decimal part, return the formatted integer part with the decimal part
  // Otherwise, just return the formatted integer part
  const value =  decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart
  return value;
}

export const numberToWords = (num) => {
    const value = formatNumber(num);
    const belowTwenty = [
        'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 
        'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 
        'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const tens = [
        '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];
    const thousands = ['','Thousand', 'Million', 'Billion'];

    if (num === 0) return belowTwenty[0];

    const helper = (n) => {
        if (n < 20) return belowTwenty[n];
        if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? ' ' + belowTwenty[n % 10] : '');
        if (n < 1000) return belowTwenty[Math.floor(n / 100)] + ' Hundred' + (n % 100 ? ' ' + helper(n % 100) : '');
        for (let i = 0; i < thousands.length; i++) {
            const unit = Math.pow(1000, i);
            if (n < unit * 1000) {
                return helper(Math.floor(n / unit)) + ' ' + thousands[i] + (n % unit ? ' ' + helper(n % unit) : '');
            }
        }
    };

    const [integerPart, fractionalPart] = num.toString().split('.');
    let result = helper(Number(integerPart));

    if (fractionalPart) {
        result += ' Point';
        for (const digit of fractionalPart) {
            result += ' ' + belowTwenty[Number(digit)];
        }
    }

    if((result == undefined) && (value == "")){
      return  "";
    } else if(result == undefined && (value != "")){
      return value;
    } else {
      return result + " (" + value + ")";
    }
};

export const getExponent = (value) => {
    if(value == 0) return 0;
    const baseLog = Math.log(value) / Math.log(10);
    return Math.trunc(baseLog);
}

export const getMantissa = (value) => {
    if(value == 0) return 0;
    const baseLog = Math.log(value) / Math.log(10);
    return value / (10 ** Math.trunc(baseLog));
}

export function findStars(correct, response, maxCategoryValue, task) {
    let stars, error;

    // max exponent between the selected categories
    const maxCategoryExp = getExponent(maxCategoryValue);

    // exponents of correct value and response
    const expMax = getExponent(Math.max(correct, response));
    const expMin = getExponent(Math.min(correct, response));
   // mantissas of correct value and response
    const mantMax = getMantissa(Math.max(correct, response));
    const mantMin = getMantissa(Math.min(correct, response));



    error = expMax - expMin + (mantMax-mantMin)/9

    if(task == "difference"){
      // if the difference between the response and the correct answer is more than 2 exponents appart, for example if the user answered that the difference is zero,
      // check if the "visual" difference is invisible. That happens when the two values are in the same or neighboring exponents and the correct answer is 
      // two or more exponent less than the max exponent
      if(error >=2 && maxCategoryExp - getExponent(correct) >= 2){
        if(response <= maxCategoryValue){ //if the response is less than the max value
          error = 0; // the user provided an estimation close to the visible value
        } // else the user provided a wrong estimation and the previous error measure is valid
      }
      
    }
    
    // stars based on the error
    if(error <= 0.12) { // 1/9=0.1111
      //same exponent mantissa +-1
      stars = 5;
    } else if(error < 0.22){ // 2/9=0.2222
      // less than 2 digits difference for mantissa
      stars = 4;
    } else if(error <= 1){
      // more than 2 digits difference for mantissa but in the same exponent
      stars = 3;
    } else if(error < 2){
      // wrong but neighbour exponent
      stars = 2;
    } else if(error >= 2) {
      // different exponent
      stars = 1;
    } else {
      // probably there is a bug if the stars are 0
      stars = 0;
    }
    return stars;
  }

  export const getStars = (blockStars, blockTrials) => {
    const totalStarsRating = 5;
    const stars = blockStars / blockTrials;
    let fullStars = Math.floor(stars);
    let hasHalfStar = false;

    const floatPart = stars - fullStars;

    if (floatPart > 0.75) {
        fullStars += 1;
    } else if (floatPart >= 0.25 && floatPart <= 0.75) {
        hasHalfStar = true;
    }

    const emptyStars = totalStarsRating - fullStars - (hasHalfStar ? 1 : 0);

    return { fullStars, hasHalfStar, emptyStars };
};




