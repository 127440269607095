import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
// Custom hooks
import useWindowDimensions from '../useWindowDimensions';


const LogChart = ({data, selectedCategory1, selectedCategory2, width, height}) => {
  // Ref to hold the SVG container
  const chartContainer = useRef(null);

  // constants
  const marginLeft = 60;
  const marginRight = 60;
  const marginTop = 40; 
  const marginBottom = 60;
  //const { width, height } = useWindowDimensions();
  // const width = width;
  // const height = height;
  const maxExponent = Math.floor(Math.log10(d3.max(data, d => parseFloat(d.Value))));
  const minExponent = Math.floor(Math.log10(d3.min(data, d => parseFloat(d.Value))));
  const selectedColor = "#F85741";
  const evenBackgroundColor = "#ffffff";  // Background color for even exponents
  const oddBackgroundColor = "#f0f0f0";  // Background color for odd exponents

  // Specify categories to change color
  const category1 = data.find(category => category.Category === selectedCategory1);
  const category2 = data.find(category => category.Category === selectedCategory2);
  const categoriesToHighlight = [];
  if(category1 != undefined) categoriesToHighlight.push(category1);
  if(category2 != undefined) categoriesToHighlight.push(category2);


  useEffect(() => {
    // Function to update the chart when data or visual variables or digits change
    if (data === undefined) return;
    updateChart();
  }, [data]);

  const scaleY = (value) => {
    let num, unit;
    if(value >= 1000 && value <1000000){
      num = parseInt(value/1000)
      unit = "k"
    } else if (value >= 1000000 && value <1000000000){
      num = parseInt(value/1000000)
      unit = "M"
    } else if (value >= 1000000000 && value <1000000000000){
      num = parseInt(value/1000000000)
      unit = "B"
    } else {
      num = value;
      unit = "";
    }
    return num + unit;
}



  // Function to update the D3 chart
  const updateChart = () => {
      // Custom tick values for the y-axis
    // const logTicks = [];
    // for (let exp = 0; exp <= maxExponent; exp++) {
    //     for (let m = 1; m < 10; m++){
    //     logTicks.push(m * Math.pow(10, exp));
    //     }
    // }

    const displayTicks = [];
    for (let exp = 0; exp <= maxExponent; exp++) {
        displayTicks.push(1 * Math.pow(10, exp));
        displayTicks.push(5 * Math.pow(10, exp));
    }

    const strongTicks = [];
    for (let exp = 0; exp <= maxExponent + 1; exp++) {
        strongTicks.push(1 * Math.pow(10, exp));
    }

    const lightTicks = [];
    for (let exp = 0; exp <= maxExponent; exp++) {
        lightTicks.push(5 * Math.pow(10, exp));
    }

    const horizontalLines = [];
    for (let exp = 0; exp <= maxExponent; exp++) {
        horizontalLines.push(2.5 * Math.pow(10, exp));
        horizontalLines.push(5 * Math.pow(10, exp));
        horizontalLines.push(7.5 * Math.pow(10, exp));
    }

    // Declare the x (horizontal position) scale.
    const x = d3.scaleBand()
    .domain(data.map(d => d.Category).sort())
    // .domain(d3.shuffle(data.map(d => d.Category))) // Shuffle the order randomly
    .range([marginLeft, width - marginRight])
    .padding(0.4);

    // Declare the y (vertical position) scale.
    const y = d3.scaleLog()
    .domain([1, 10**(maxExponent+1)])
    .range([height - marginBottom, marginTop]);

    // Select the SVG container
    const svg = d3.select(chartContainer.current);

    svg.attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; max-height:100%; min-width: 100%; min-height:100%");

    // Remove existing elements to avoid duplication
    svg.selectAll('*').remove();

    // Add background rectangles for each exponent group
    for (let exp = 0; exp <= maxExponent; exp++) {
        svg.append("rect")
            .attr("x", marginLeft)
            .attr("y", y(10**(exp + 1)))  // top of the exponent range
            .attr("width", width - marginLeft - marginRight)
            .attr("height", y(10**exp) - y(10**(exp + 1)))  // height of the exponent range
            .attr("fill", exp % 2 === 0 ? evenBackgroundColor : oddBackgroundColor);
    }


    // Define the arrow marker
    svg.append("defs").append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 5)
      .attr("refY", 5)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 z")
      .attr("fill", selectedColor);

    // Add horizontal grid lines - thick
    svg.append("g")
    .attr("class", "grid-strong")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(strongTicks)
        .tickSize(-width + marginLeft + marginRight)
        .tickFormat('')
    ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

    // Add horizontal grid lines - thin
    svg.append("g")
    .attr("class", "grid")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(horizontalLines)
        .tickSize(-width + marginLeft + marginRight)
        .tickFormat('')
    ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

    // Add the x-axis and label.
    const xAxis = svg.append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x).tickSizeOuter(0));

    // Strong ticks left.
    svg.append("g")
    .attr("transform",  `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y).tickValues(strongTicks).tickFormat(d=> scaleY(d)).tickSize(4))
        .selectAll(".tick text")
        .classed("strongText", true)
    .call(g => g.select(".domain").remove())

    // Light ticks left.
    svg.append("g")
    .attr("transform",`translate(${marginLeft},0)`)
    .call(d3.axisLeft(y).tickValues(lightTicks).tickFormat(d=> scaleY(d)).tickSize(4))
        .selectAll(".tick text")
        .classed("lightText", true)
    .call(g => g.select(".domain").remove())

    // Ticks without text and Left axis.
    // svg.append("g")
    // .attr("transform", `translate(${marginLeft},0)`)
    // .call(d3.axisLeft(y).tickValues(logTicks).tickFormat('').tickSize(4))

    // Strong ticks right.
    svg.append("g")
    .attr("transform", `translate(${width - marginRight},0)`)
    .call(d3.axisRight(y).tickValues(strongTicks).tickFormat(d=> scaleY(d)).tickSize(4))
        .selectAll(".tick text")
        .classed("strongText", true)
    .call(g => g.select(".domain").remove())

    // Light ticks right.
    svg.append("g")
    .attr("transform", `translate(${width - marginRight},0)`)
    .call(d3.axisRight(y).tickValues(lightTicks).tickFormat(d=> scaleY(d)).tickSize(4))
        .selectAll(".tick text")
        .classed("lightText", true)
    .call(g => g.select(".domain").remove())

    // Ticks without text and right axis.
    // svg.append("g")
    // .attr("transform", `translate(${width - marginRight},0)`)
    // .call(d3.axisRight(y).tickValues(logTicks).tickFormat('').tickSize(4))

      // Add a rect for each bar.
    svg.append("g")
        .attr("fill", "#14164C")
        .selectAll()
        .data(data)
        .join("rect")
        .attr("x", (d) => x(d.Category))
        .attr("y", (d) => y(d.Value))
        .attr("height", (d) => y(1) - y(d.Value))
        .attr("width", x.bandwidth());

    // Add x-axis label
    // svg.append("text")
    //     .attr("x", width - marginRight)
    //     .attr("y", height - marginBottom/4)
    //     .attr("text-anchor", "end")
    //     .text("Categories")
    //     .attr("font-size", "14px");

    // Add y-axis label
    svg.append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop - 15)
        .attr("text-anchor", "end")
        .text("Value")
        .style("font-size", "14px");

      // Add legend for units explanation
    svg.append("text")
        .attr("x", marginLeft)
        .attr("y", height-5)  // Position it above the bottom margin
        .attr("text-anchor", "start")
        .text("k = Thousand (1,000),  M = Million (1,000,000),  B = Billion (1,000,000,000)")
        .attr("alignment-baseline", "start")
        .style("font-size", "14px");


    // Change color of specific categories on x-axis
    xAxis.selectAll(".tick text")
        .style("font-size","14px")
        .style("fill", function(d) {
            return categoriesToHighlight.some(c => c.Category === d) ? selectedColor : "black";
        })
        .style("font-weight", function(d) {
            return categoriesToHighlight.some(c => c.Category === d) ? "bold" : "normal";
        });

       // Draw arrows pointing to the highlighted ticks
       categoriesToHighlight.forEach(c => {
        if(c.Category == "") return;
        const xPosition = x(c.Category) + x.bandwidth() / 2;
        const yPositionBottom = height-marginBottom/2; // Position below the x-axis
        const yPositionTop = y(c.Value); // Position on top of bar

        svg.append("line")
          .attr("x1", xPosition)
          .attr("y1", yPositionBottom ) 
          .attr("x2", xPosition)
          .attr("y2", yPositionBottom  - 5) 
          .attr("stroke", selectedColor)
          .attr("stroke-width", 2)
          .attr("marker-end", "url(#arrow)");

          // Calculate the midpoint for rotation
          const xMidpoint = xPosition;
          const yMidpoint = yPositionTop - 20; // Midpoint of the line
  
          svg.append("line")
            .attr("x1", xPosition)
            .attr("y1", yPositionTop) 
            .attr("x2", xPosition)
            .attr("y2", yPositionTop - 30) 
            .attr("stroke", selectedColor)
            .attr("stroke-width", 2)
            .attr("marker-end", "url(#arrow)")
            .attr("transform", `rotate(180, ${xMidpoint}, ${yMidpoint})`);

    });
    
  };

  // Render the SVG container
  return <svg ref={chartContainer}></svg>;
};

export default LogChart;