export const facet = [
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/facet-start.svg', 
        title:"The Visualization (1/7)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/facet-axis.svg', 
        title:"The Visualization (2/7)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/facet-scale.gif', 
        title:"The Visualization (3/7)", 
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/facet-inside-0.svg', 
        title:"The Visualization (4/7)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/facet-inside-1.svg', 
        title:"The Visualization (5/7)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/facet-inside-2.svg', 
        title:"The Visualization (6/7)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/facet-inside-3.svg', 
        title:"The Visualization (7/7)",  
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen1.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen2.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen3.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen4.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen5.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen6.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen7.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen8.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen9.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen10.svg', 
        title:"The Experiment", 
       
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen11.svg', 
        title:"The Experiment", 
        
    },
    {
        image: process.env.PUBLIC_URL + '/experiment-training/facet/screen12.svg', 
        title:"The Experiment", 
        
    },
]