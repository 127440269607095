import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
// Custom hooks
import useWindowDimensions from '../useWindowDimensions';


const SsbChart = ({data, selectedCategory1, selectedCategory2, width, height}) => {
  // Ref to hold the SVG container
  const chartContainer = useRef(null);

  // constants
  const marginLeft = 60;
  const marginRight = 60;
  const marginTop = 40;
  const marginBottom = 60;
  //const { width, height } = useWindowDimensions();
  // const width = 768;
  // const height = 531;
  const maxExponent = Math.floor(Math.log10(d3.max(data, d => parseFloat(d.Value))));
  const minExponent = Math.floor(Math.log10(d3.min(data, d => parseFloat(d.Value))));
  const selectedColor = "#F85741";

  // Specify categories to change color
  const category1 = data.find(category => category.Category === selectedCategory1);
  const category2 = data.find(category => category.Category === selectedCategory2);
  const categoriesToHighlight = [];
  if(category1 != undefined) categoriesToHighlight.push(category1);
  if(category2 != undefined) categoriesToHighlight.push(category2);


  useEffect(() => {
    // Function to update the chart when data or visual variables or digits change
    if (data === undefined) return;
    updateChart();
  }, [data]);

  const getExponent = (value, base) => {
    // To calculate the base-1000 logarithm using Math.log(value), 
    // you would need to adjust the formula to convert from the natural logarithm to the base-1000 logarithm.
    const baseLog = Math.log(value) / Math.log(base);
    return Math.trunc(baseLog);
  }

  const getMantissa = (value, base) => {
    // To calculate the base-1000 logarithm using Math.log(value), 
    // you would need to adjust the formula to convert from the natural logarithm to the base-1000 logarithm.
    const baseLog = Math.log(value) / Math.log(base);
    return value / (base ** Math.floor(baseLog));
  }

  const scaleSSB = (v) => {
    const exp = Math.trunc(v);
    const value = 10**(exp+1);
    let num, unit;
    if(value >= 1000 && value <1000000){
      num = parseInt(value/1000)
      unit = "k"
    } else if (value >= 1000000 && value <1000000000){
      num = parseInt(value/1000000)
      unit = "M"
    } else if (value >= 1000000000 && value <1000000000000){
      num = parseInt(value/1000000000)
      unit = "B"
    } else {
      num = value;
      unit = "";
    }
    return "0-" + num + unit;
  }


  const midTicksSSB = (v) => {
    const exp = Math.trunc(v);
    const value = 5*10**(exp);
    let num, unit;
    if(value >= 1000 && value <1000000){
      num = parseInt(value/1000)
      unit = "k"
    } else if (value >= 1000000 && value <1000000000){
      num = parseInt(value/1000000)
      unit = "M"
    } else if (value >= 1000000000 && value <1000000000000){
      num = parseInt(value/1000000000)
      unit = "B"
    } else {
      num = value;
      unit = "";
    }
    return num + unit;
  }

  // Function to update the D3 chart
  const updateChart = () => {
      // Custom tick values for the y-axis
  const expTicks = [];
  for (let exp = minExponent; exp <= maxExponent + 1; exp++) {
      expTicks.push(exp);
  }


// Custom tick values for the y-axis
  const ticks = [];
  for (let exp = minExponent; exp <= maxExponent; exp++) {
    for (let m = 0.25; m < 1; m=m+0.25){
      ticks.push(exp + m);
    }
  }

  // Custom mid tick values for the y-axis
  const midticks = [];
  for (let exp = minExponent; exp <= maxExponent; exp++) {
      midticks.push(exp + 0.5);
  }

  
  const allTicks = ticks.filter(t => t >= minExponent && t <= maxExponent + 1);

  // Declare the x (horizontal position) scale.
  const x = d3.scaleBand()
      .domain(data.map(d => d.Category).sort())
      // .domain(d3.shuffle(data.map(d => d.Category))) // Shuffle the order randomly
      .range([marginLeft, width - marginRight])
      .padding(0.4);
  
  // Declare the y (vertical position) scale.
  const y = d3.scaleLinear()
      .domain([minExponent, maxExponent + 1])
      .range([height - marginBottom, marginTop]);

 // Manually define an array of 20 colors.
  const colors = [
    "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd",
    "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf",
    "#393b79", "#637939", "#8c6d31", "#843c39", "#7b4173",
    "#5254a3", "#6b6ecf", "#9c9ede", "#dadaeb", "#969696"
  ];

  // Declare the color scale.
  const color = d3.scaleOrdinal()
      .domain(data.map(d => d.Category))
      .range(colors);


    // Select the SVG container
    const svg = d3.select(chartContainer.current);

    svg.attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; max-height:100%; min-width: 100%; min-height:100%");

    // Remove existing elements to avoid duplication
    svg.selectAll('*').remove();

    // Define the arrow marker
    svg.append("defs").append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 5)
      .attr("refY", 5)
      .attr("markerWidth", 6)
      .attr("markerHeight", 6)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 z")
      .attr("fill", selectedColor);

    // Add horizontal grid lines - thin
    svg.append("g")
    .attr("class", "grid")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(ticks)
        .tickSize(-width + marginLeft + marginRight)
        .tickFormat('')
    ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

    // Add horizontal grid lines - thick
    svg.append("g")
    .attr("class", "grid-strong")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(expTicks)
        .tickSize(-width + marginLeft + marginRight)
        .tickFormat('')
    ).call(g => g.select(".domain").remove()); // This line removes the vertical domain line of the grid

    // Add a rect for each bar.
    svg.append("g")
    .selectAll()
    .data(data)
    .join("rect")
    .attr("x", (d) => x(d.Category))
    .attr("y", (d) => y(getExponent(d["Value"], 10) + getMantissa(d["Value"], 10)/10))
    .attr("height", (d) => y(getExponent(d["Value"], 10)) - y(getExponent(d["Value"], 10) + getMantissa(d["Value"], 10)/10))
    .attr("width", x.bandwidth())
    .attr("fill", d => color(d.Category))
    .style("stroke", "black");


    svg.selectAll(".bars-for-next-exponents")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "bars-for-next-exponents")
    .attr("transform", (d, i) => `translate(${x(d.Category)}, 0)`)
    .each(function (d, i) {
    const bars = d3.select(this);
    for (let exp = getExponent(d.Value, 10) + 1; exp <= maxExponent; exp++) {
        //const expData = [{ ...d, exp }];

        bars.selectAll(`.bars-exp-${exp}`)
        .data(data)
        .enter()
        .append("rect")
        .attr("class", `bars-exp bars-exp-${exp}`)
        .attr("width", x.bandwidth())
        .attr("height", d["Value"]/(10**(exp-1)))
        .attr("x", 0)
        .attr("y", y(exp) - d["Value"]/(10**(exp-1))) // Adjust y position based on exponent
        .style("fill", color(d.Category))
        .style("stroke", "black");
    }
    });

    // Add dashed vertical lines
    svg.append("g")
    .attr("class", "dashed-lines")
    .selectAll("line")
    .data(data)
    .enter().append("line")
    .attr("class", "dashed-line")
    .attr("x1", d => x(d.Category) + x.bandwidth() / 2)
    .attr("y1", height - marginBottom)
    .attr("x2", d => x(d.Category) + x.bandwidth() / 2)
    .attr("y2", d => y(getExponent(d["Value"], 10)));



    // Add the x-axis and label.
    const xAxis = svg.append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(d3.axisBottom(x).tickSizeOuter(0));


    // Strong text left axis.
    svg.append("g")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y)
        .tickValues(midticks)
        .tickFormat(d=>scaleSSB(d))
        .tickSize(0))
    .selectAll(".tick text")
    .classed("strongSSBText", true)


    // Change color of specific categories on x-axis
    xAxis.selectAll(".tick text")
        .style("font-size","14px")
        .style("fill", function(d) {
            return categoriesToHighlight.some(c => c.Category === d) ? selectedColor : "black";
        })
        .style("font-weight", function(d) {
            return categoriesToHighlight.some(c => c.Category === d) ? "bold" : "normal";
        });

    // Light text right axis.
    svg.append("g")
    .attr("transform", `translate(${width - marginRight},0)`)
    .call(d3.axisRight(y).tickValues(midticks).tickFormat(d=> midTicksSSB(d)).tickSize(4))
        .selectAll(".tick text")
        .classed("lightText", true)
    .call(g => g.select(".domain").remove())

    // Add y-axis label
    svg.append("text")
        .attr("x", marginLeft)
        .attr("y", marginTop - 10)
        .attr("text-anchor", "end")
        .text("Value")
        .attr("font-size", "14px");

      // Add legend for units explanation
    svg.append("text")
        .attr("x", marginLeft)
        .attr("y", height - 10)  // Position it above the bottom margin
        .attr("text-anchor", "start")
        .text("k = Thousand (1,000),  M = Million (1,000,000),  B = Billion (1,000,000,000)")
        .attr("alignment-baseline", "start")
        .attr("font-size", "14px");

    // Draw arrows pointing to the highlighted ticks
    categoriesToHighlight.forEach(c => {
      if(c.Category == "") return;
      const xPosition = x(c.Category) + x.bandwidth() / 2;
      const yPositionBottom = height-marginBottom/2; // Position below the x-axis
      const yPositionTop = y(maxExponent + getMantissa(c["Value"], 10)/10**((maxExponent+1) - getExponent(c["Value"], 10))); // Position on top of bar

      svg.append("line")
        .attr("x1", xPosition)
        .attr("y1", yPositionBottom ) 
        .attr("x2", xPosition)
        .attr("y2", yPositionBottom  - 5) 
        .attr("stroke", selectedColor)
        .attr("stroke-width", 2)
        .attr("marker-end", "url(#arrow)");

        // Calculate the midpoint for rotation
        const xMidpoint = xPosition;
        const yMidpoint = yPositionTop - 20; // Midpoint of the line

        svg.append("line")
          .attr("x1", xPosition)
          .attr("y1", yPositionTop) 
          .attr("x2", xPosition)
          .attr("y2", yPositionTop - 30) 
          .attr("stroke", selectedColor)
          .attr("stroke-width", 2)
          .attr("marker-end", "url(#arrow)")
          .attr("transform", `rotate(180, ${xMidpoint}, ${yMidpoint})`);

  });
    
  };

  // Render the SVG container
  return <svg ref={chartContainer}></svg>;
};

export default SsbChart;